import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { login } from '../../redux/thunks/Auth';
import { RequestStatus } from '../../utils/Constants';
import { isEmailValid } from '../../utils/Validator';
import { useLocation } from 'react-router-dom';
import Button from '../sub-component/Button';
import Input from '../sub-component/Input';
import { useDispatch, useSelector } from 'react-redux';
import AlertBox from '../common/AlertBox';
// import { Link } from 'react-router-dom';
import Checkbox from '../sub-component/Checkbox';
import { ReactComponent as GoogleIcon } from '../../assets/icons/logo/google_icon.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/basic/x-circle-error.svg';
import { getGoogleOauthOpts } from '../../utils/utilities';
import { PathName } from '../../utils/routes';

const LoginForm = () => {

	/**@type {AuthState} */
	const auth = useSelector((state) => state.authReducer);

	const search = useLocation().search;
	const searchParams = new URLSearchParams(search);
	const failure = searchParams.get('failure');

	const history = useHistory();
	const dispatch = useDispatch();
	const [values, setValues] = useState({ email: '', password: '', rememberMe: false });
	const [alert, setAlert] = useState(null);
	const [emailError, setEmailError] = useState('');
	const [passwordError, setPasswordError] = useState('');

	useEffect(() => {
		if (failure) {
			setAlert(
				<AlertBox description={`${failure}!`} color='danger' title="Error" onCloseAlert={() => { setAlert(null); }} />
			);
			searchParams.delete('failure');
			history.replace(`${PathName.LOGIN}?${searchParams.toString()}`);
		}
	}, [failure]);

	const [reveal, setReveal] = useState({ revealPassword: false, revealConfirmPassword: false });

	const shouldRevealPassword = (revealOf) => {
		setReveal({ ...reveal, [revealOf]: !reveal[revealOf] });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!values.email.trim() || !isEmailValid(values.email.trim())) {
			setEmailError('Please enter a valid email');
			return;
		} else {
			setEmailError('');
		}
		if (!values.password.trim()) {
			setPasswordError('Please enter a valid password');
			return;
		} else {
			setPasswordError('');
		}
		if (isEmailValid(values.email.trim()) && values.password.trim()) {
			const { payload, meta } = await dispatch(login({ email: values.email.trim(), password: values.password, remember_me: values.rememberMe }));
			if (meta.requestStatus === RequestStatus.REJECTED) {
				setAlert(
					<AlertBox description={`${payload}!`} color='danger' title="Error" onCloseAlert={() => { setAlert(null); }} />
				);
				return;
			}
			// window.location.replace(PathName.MAIN);
			// history.push('/activation');
			// return ;
		}
		window.location.reload();


	};

	const handleChange = (valueToChange, e) => {
		setValues({ ...values, [valueToChange]: e });
	};

	const LogInWithGoogleHandler = () => {
		const { options, rootUrl } = getGoogleOauthOpts();

		const qs = new URLSearchParams(options);
		const searchParams = new URLSearchParams(search);
		const searchParamsObj = Object.fromEntries(searchParams);

		const additionalParams = {
			...searchParamsObj,
			authProcess: 'login'
		};
		const state = JSON.stringify(additionalParams);

		const googleConsentScreenUrl = `${rootUrl}?${qs.toString()}&state=${encodeURIComponent(state)}`;

		window.location.href = googleConsentScreenUrl;
	};

	return (
		<div className="login-input-group">
			<div className='alert-wrapper'>
				{alert}
			</div>

			<form onSubmit={handleSubmit}>
				{emailError ? <div className="error-text">
					<div style={{ width: '30px' }}>
						<ErrorIcon />
					</div>
					{emailError}
				</div> :
					<>
					</>}
				{passwordError ? <div className="error-text">
					<div style={{ width: '30px' }}>
						<ErrorIcon />
					</div>
					{passwordError}
				</div> :
					<>
					</>}

				<div className="d-flex flex-column mt-4" >
					<label className='body-2 dark-50 mb-2' style={{ marginLeft: '24px' }}>Email</label>
					<Input
						// placeholder="Email"
						onChange={(e) => { handleChange('email', e?.target?.value); }}
						type="text"
						value={values.email}
						onBoardingInput
						className={emailError ? 'error-input' : ''}
						name='email'
						varrient={'primary'}
					/>
				</div>
				<div className="d-flex flex-column mt-4" >
					<label className='body-2 dark-50 mb-2' style={{ marginLeft: '24px' }}>Password</label>
					<Input
						name={'password'}
						// placeholder="Password"
						onChange={(e) => { handleChange('password', e?.target?.value); }}
						type={reveal.revealPassword ? 'text' : 'password'}
						value={values.password}
						onBoardingInput
						enableRevealIcon
						revealPasswordClick={() => { shouldRevealPassword('revealPassword'); }}
					// varrient={'primary'}
					/>
				</div>

				<div className='d-flex justify-content-between w-100 mt-3 align-items-center'>
					<div >
						<Checkbox id={'login-checkbox'} onChange={() => { handleChange('rememberMe', !values.rememberMe); }} value={values.rememberMe} primary title='Remember Me' />
					</div>
					<div className=" pointer body-3 underline" onClick={() => {
						history.push('/forget-password');
					}}>
						Forgot password?
					</div>
				</div>

				<div className="submit-button mt-4">
					<Button style={{ width: '100%', padding: '0px 14px', height: '34px' }} varrient="primary" btn="ragular-btn" type={'submit'} title={'Login'} processing={auth.is_loading} />
				</div>

			</form>
			<div className='d-flex justify-content-between align-items-center mt-2'>
				<div style={{ border: '1px solid #ECEDF1', width: '45%' }}></div>
				<div className="body-2 black-700 d-flex justify-content-center align-content-center">
					{`Or`}
				</div>
				<div style={{ border: '1px solid #ECEDF1', width: '45%' }}></div>

			</div>
			<div style={{ marginTop: '12px' }}>
				<Button type={'submit'} onClick={LogInWithGoogleHandler} isGoogleAuth={true} style={{ width: '100%', padding: '10px 24px 10px 24px', height: '34px' }} btn="ragular-btn" varrient="secondary-btn" title={'Login With Google'} className='btn-md d-flex align-items-center justify-content-center' processing={auth.is_loading} preIcon={<GoogleIcon style={{ width: '30px' }} />} />
			</div>

		</div>
	);
};
export default LoginForm;
