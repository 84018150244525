import React, {useState, useEffect} from 'react';
import { ReactComponent as AlertIcon } from '../../../assets/icons/pricing/alert-circle.svg';
import ProcessingIndicator from '../../../components/common/ProcessingIndicator';
import { getResource, getPublicResource } from '../../../redux/thunks/Resource';
import { useDispatch } from 'react-redux';

export default function PersonalityResponseDetails(props) {
	const {assessment, isForPublic} = props;
	const responses = assessment?.tests?.filter((t) => t.test_type === 'personality_test')[0]?.prev_test_results ? 
		assessment?.tests?.filter((t) => t.test_type === 'personality_test')[0]?.prev_test_results?.responses 
		: 
		assessment?.responses?.filter((r) => r.test_type === 'personality_test');

	const UploadResponseDetails = (props) => {
		const { response } = props;
		const dispatch = useDispatch();
		const [resource, setResource] = useState(null);
		const [loading, setLoading] = useState(true);

		useEffect(() => {
			(async () => {
				if (!response.resource_id) return;

				setLoading(true);
				const dispatched = await dispatch(
					isForPublic ?
						getPublicResource({ 
							data: {
								resource_id: response.resource_id,
								assess_id: assessment?.id,
							}
						})
						:
						getResource({ id: response.resource_id })
				);
				setLoading(false);
				if (dispatched.error) return;
				setResource(dispatched.payload);
			})();
		}, [response]);

		if (loading && response.resource_id) {
			return <ProcessingIndicator className='small-indicator' />;
		}

		return (
			<>
				{ response.resource_id ? (
					<video
						style={{ width: '100%', height: '250px', borderRadius: '12px', background: '#121216', objectFit: 'contain' }}
						src={resource?.url}
						controls={true}
					/>
				) : (
					<span className='body-2 danger-text' style={{ textAlign: 'left' }}>
						Failed to load resource!
					</span>
				)}
			</>
		);
	};


	return (
		<div className='w-100 d-flex flex-column' style={{ gap: '24px' }}>
			{assessment?.personality_test_result?.error &&
				<div className='w-100 d-flex flex-row align-items-center' style={{ padding: '12px 18px', borderRadius: '24px', background: '#F6F5F8', gap: '8px' }}>
					<span><AlertIcon style={{width: '20px', height: '20px'}} /></span>
					<span className='body-2 dark-100 text-start'>{assessment?.personality_test_result?.error?.message}</span>
				</div>
			}
			<div className='w-100 d-flex flex-column align-items-start' style={{ padding: '24px', borderRadius: '24px', border: '1px solid #ECEDF1', background: '#FFF', gap: '16px' }}>
				<span className='subtitle-2 dark-100'>Text & Video Responses</span>
				{ responses?.map((response, i) => (
					<div key={i} className='w-100 d-flex flex-column align-items-start' style={{ padding: '24px', borderRadius: '24px', border: '1px solid #ECEDF1', background: '#F6F5F8', gap: '12px' ,overflow:'hidden'}}>
						<span className='body-2 dark-100 text-start'>{response?.text}</span>
						{(response?.personality_readiness && response?.personality_readiness?.content_status !== 'ready') &&
							<div className='w-100 d-flex flex-row align-items-center' style={{ padding: '12px 18px', borderRadius: '24px', background: '#FFF', gap: '8px' }}>
								<span><AlertIcon style={{width: '20px', height: '20px'}} /></span>
								<span className='body-2 dark-100 text-start'>
									{`The response content ${response?.personality_readiness?.content_status === 'gibberish' ? 'contains gibberish' : response?.personality_readiness?.content_status === 'empty_content' ? 'is empty' : 'is insufficient'}.`}
								</span>
							</div>
						}
						{response.ques_type === 'text_response' ?
							<span className='body-2 dark-100 text-start' style={{overflowWrap:'break-word',wordBreak:'break-word'}}>{response?.response_text}</span>
							:
							<>
								<UploadResponseDetails response={response} />
								<div className='w-100 d-flex flex-column align-items-start' style={{ padding: '24px', borderRadius: '24px', border: '1px solid #ECEDF1', background: '#FFFFFF', gap: '12px' }}>
									<span className='body-2 dark-50 text-start'>Transcription:</span>
									<span className='body-2 dark-100 text-start'>{response?.transcription}</span>
								</div>
							</>
						}
					</div>
				))}
			</div>
		</div>
	);
}
