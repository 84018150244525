/* eslint-disable indent */
import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as CTIcon } from "../../../assets/icons/components/dashboard/assessment-result-card/code.svg";
import { ReactComponent as SBTIcon } from "../../../assets/icons/components/dashboard/assessment-result-card/skill.svg";
import { ReactComponent as VFIcon } from "../../../assets/icons/components/dashboard/assessment-result-card/value.svg";
import { ReactComponent as PTIcon } from "../../../assets/icons/components/dashboard/assessment-result-card/smile.svg";
import { ReactComponent as TRIcon } from "../../../assets/icons/components/dashboard/assessment-result-card/text.svg";
// import { ReactComponent as CSIcon } from '../../../assets/icons/components/dashboard/assessment-result-card/Frame 2608688(4).svg';
import { ReactComponent as CSIcon } from "../../../assets/icons/components/dashboard/assessment-result-card/cst.svg";
import { ReactComponent as VRIcon } from "../../../assets/icons/components/dashboard/assessment-result-card/video.svg";
import { ReactComponent as FUIcon } from "../../../assets/icons/components/dashboard/assessment-result-card/upload.svg";
import { ReactComponent as ClockIcon } from "../../../assets/icons/components/dashboard/assessment-result-card/clock.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/components/dashboard/assessment-result-card/arrow-down.svg";
import { ReactComponent as ExpireIcon } from "../../../assets/icons/components/dashboard/assessment-result-card/expire-icon.svg";
import { ReactComponent as InProgressIcon } from "../../../assets/icons/components/dashboard/assessment-result-card/in-progress.svg";
import { ReactComponent as ResultInProgressIcon } from "../../../assets/icons/components/dashboard/assessment-result-card/Result-in-progress.svg";
import { ReactComponent as EyeIcon } from "../../../assets/icons/components/dashboard/assessment-result-card/eye.svg";
import { ReactComponent as DocIcon } from "../../../assets/icons/components/dashboard/assessment-result-card/text-document.svg";
import ProcessingIndicator from "../../common/ProcessingIndicator";
import { getResource, getPublicResource } from "../../../redux/thunks/Resource";
import { useDispatch, useSelector } from "react-redux";
import { secondsToDhmsFormat, calculateScore } from "../../../utils/utilities";
import ReactQuill from "react-quill";
import {
  CaseStudyQuestionTypes,
  TestType,
  UserRole,
} from "../../../utils/Constants";
import CustomModal from "../../common/Modal";
import { CommonContext } from "../../../utils/contexts/CommonContext";
import { getPublicCodeReport } from "../../../redux/thunks/PublicResult";
import { getCodingTestSnapShots } from "../../../redux/thunks/Assessment";
import { useMediaQuery } from "@mui/material";
import LoomEmbedVideo from "../../common/LoomEmbedVideo";
import { PersonalityType } from "../../../utils/personalityType";
import CustomTooltip from "../../common/CustomTooltip";
import { PathName } from "../../../utils/routes";
import { useHistory } from "react-router-dom";

const TestDetailsUploadResponse = (props) => {
  const { test_type, response, assessment, isForPublic } = props;

  const [resource, setResource] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = useSelector((state) => state.authReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (!response.resource_id) return;

      setLoading(true);
      const dispatched = await dispatch(
        isForPublic
          ? getPublicResource({
              data: {
                resource_id: response.resource_id,
                assess_id: assessment?.id,
              },
            })
          : getResource({ id: response.resource_id })
      );
      setLoading(false);
      if (dispatched.error) return;
      setResource(dispatched.payload);
    })();
  }, [response]);

  if (loading && response.resource_id) {
    return <ProcessingIndicator className="small-indicator" />;
  }

  if (
    test_type === "video_test" ||
    test_type === CaseStudyQuestionTypes.VIDEO_RESPONSE
  ) {
    return (
      <>
        {response?.loom_video_url ? (
          <LoomEmbedVideo
            backUpVideoSrc={resource}
            loomUrl={response?.loom_video_url}
          />
        ) : response.resource_id ? (
          <video
            className="video-response-result"
            src={resource?.url}
            controls={true}
          />
        ) : (
          <span className="body-2 danger-text" style={{ textAlign: "left" }}>
            Failed to load resource!
          </span>
        )}
      </>
    );
  } else {
    return (
      <>
        {!response.resource_id ? null : resource ? (
          <>
            {(test_type === "upload_test" ||
              test_type === "text_test" ||
              test_type === CaseStudyQuestionTypes.UPLOAD_RESPONSE) && (
              <a
                style={{ textDecoration: "none" }}
                className="file-upload-result-doc"
                href={resource?.url}
                target="_blank"
                download={true}
                rel="noopener noreferrer"
              >
                <div
                  className="d-flex align-items-center"
                  style={{ width: "calc(100% - 100px)" }}
                >
                  <div className="d-flex">
                    <DocIcon style={{ width: "20px", height: "20px" }} />
                  </div>
                  <span
                    className="body-2 dark-100 truncated-text"
                    style={{ marginLeft: "13.5px", color: "black" }}
                  >
                    {resource.name}
                  </span>
                </div>
                <span
                  className="body-2 dark-100"
                  style={{ textDecoration: "underline" }}
                >
                  Download
                </span>
              </a>
            )}
          </>
        ) : (
          <span className="body-2 danger-text" style={{ textAlign: "left" }}>
            Failed to load resource!
          </span>
        )}
      </>
    );
  }
};

export default function AssessmentResultCard(props) {
  const isOnWebScreen = useMediaQuery("(min-width:1280px)");
  const isOnMobileScreen = useMediaQuery("(max-width: 767px)");
  const {
    test,
    onExpandTest,
    isExpand,
    responses,
    assessment,
    codingScore,
    isForPublic,
  } = props;
  const auth = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const personalityType =
    PersonalityType[assessment?.personality_test_result?.pers_type];

  const { commonValues, setCommonValues } = useContext(CommonContext);

  const calculateFinshedInTime = (_test) => {
    let totalTime = 0;
    let finishedInTime = 0;
    _test.map((_eachTest, _) => {
      totalTime += _eachTest?.total_duration || 0;
      finishedInTime += _eachTest?.finished_in || 0;
    });

    // return `${finishedInTime < 60
    // 	? `00:${finishedInTime < 10 ? `0${finishedInTime}` : finishedInTime}`
    // 	: (finishedInTime / 60 < 9 ? '0' : '') +
    // 	parseInt(finishedInTime / 60) +
    // 	':' +
    // 	(finishedInTime / 60 / 60 < 9 ? '0' : '') +
    // 	parseInt(finishedInTime / 60 / 60)
    // 	}
    // out of
    // ${(totalTime / 60 < 9 ? '0' : '') +
    // 	parseInt(totalTime / 60) +
    // 	':' +
    // 	(totalTime / 60 / 60 < 9 ? '0' : '') +
    // 	parseInt(totalTime / 60 / 60)
    // 	}`;

    return `${secondsToDhmsFormat(finishedInTime)} out of ${secondsToDhmsFormat(
      totalTime
    )}`;
  };

  const viewTestDetail = async (test) => {
    dispatch(
      getCodingTestSnapShots({
        assess_id: test?.prev_test_results
          ? test?.prev_test_results?.assess_id
          : assessment?.id,
        test_id: test?.prev_test_results
          ? test?.prev_test_results?.test_id
          : test.id,
      })
    );
    setCommonValues({
      ...commonValues,
      detailedTestView: {
        isExpanded: true,
        testType: test.test_type,
        data: null,
      },
    });
  };

  const viewPublicTestDetail = async (test) => {
    dispatch(
      getPublicCodeReport({
        data: {
          assess_id: test?.prev_test_results
            ? test?.prev_test_results?.assess_id
            : assessment?.id,
          test_id: test?.prev_test_results
            ? test?.prev_test_results?.test_id
            : test.id,
        },
      })
    );
    setCommonValues({
      ...commonValues,
      detailedTestView: {
        isExpanded: true,
        testType: test.test_type,
        data: null,
      },
    });
  };

  const viewTestHistory = (data) => {
    history.push(
      `${PathName.CANDIDATES_TEST_HISTORY}?test_id=${data?.prev_test_results?._id}`
    );
  };

  return (
    <>
      {
        <div
          className="assessment-result-card-container "
          style={{ marginBottom: "0px", gap: isExpand ? "8px" : "0px" }}
        >
          <CustomTooltip
            varrient="result-sidebar-tooltip"
            customPosition={{ top: "-70px" }}
            text={
              test[0]?.completed
                ? `${
                    (test[0]?.test_type === "coding_test" &&
                      codingScore === null) ||
                    (test[0]?.test_type === "personality_test" &&
                      !assessment?.personality_test_result)
                      ? "Personality evaluation in progress, please check back later"
                      : ""
                  }`
                : assessment?.status === "EXPIRED"
                ? "Section expired"
                : test[0]?.prev_test_results
                ? ""
                : "The candidate has not attempted this test yet"
            }
          >
            <div
              className={`${
                test[0]?.completed || test[0]?.prev_test_results
                  ? `${
                      (test[0]?.test_type === "coding_test" &&
                        codingScore === null) ||
                      (test[0]?.test_type === "personality_test" &&
                        !assessment?.personality_test_result)
                        ? "assessment-result-card-header-not-cmlt"
                        : "assessment-result-card-header"
                    }`
                  : assessment?.status === "EXPIRED"
                  ? "assessment-result-card-header-expired"
                  : "assessment-result-card-header-not-cmlt"
              } pointer ${
                isExpand && "assessment-result-card-header-expanded"
              }`}
              onClick={() => {
                test[0]?.completed || test[0]?.prev_test_results
                  ? onExpandTest()
                  : {};
              }}
            >
              <div
                className="w-100 h-100 d-flex flex-column justify-content-between"
                style={{ gap: "8px" }}
              >
                {test && test.length > 0 && (
                  <>
                    <div
                      className={`w-100 d-flex justify-content-between ${
                        isOnMobileScreen &&
                        ((!test[0]?.completed && !test[0]?.prev_test_results) ||
                          (test[0]?.test_type === "coding_test" &&
                            codingScore === null) ||
                          (test[0]?.test_type === "personality_test" &&
                            !assessment?.personality_test_result))
                          ? "flex-column align-items-start"
                          : "flex-row align-items-center"
                      }`}
                      style={{ gap: "8px" }}
                    >
                      <div className="d-flex align-items-center">
                        {test[0]?.test_type === "coding_test" ? (
                          <CTIcon
                            style={{
                              height: "20px",
                              width: "20px",
                              stroke:
                                test[0]?.completed || test[0]?.prev_test_results
                                  ? "#808191"
                                  : "#96999C",
                            }}
                          />
                        ) : test[0]?.test_type === "skill_test" ? (
                          <SBTIcon
                            style={{
                              height: "20px",
                              width: "20px",
                              stroke:
                                test[0]?.completed || test[0]?.prev_test_results
                                  ? "#808191"
                                  : "#96999C",
                            }}
                          />
                        ) : test[0]?.test_type === "value_test" ? (
                          <VFIcon
                            style={{
                              height: "20px",
                              width: "20px",
                              stroke:
                                test[0]?.completed || test[0]?.prev_test_results
                                  ? "#808191"
                                  : "#96999C",
                            }}
                          />
                        ) : test[0]?.test_type === "personality_test" ? (
                          <PTIcon
                            style={{
                              height: "20px",
                              width: "20px",
                              stroke:
                                (test[0]?.completed ||
                                  test[0]?.prev_test_results) &&
                                assessment?.personality_test_result
                                  ? "#808191"
                                  : "#96999C",
                            }}
                          />
                        ) : test[0]?.test_type === "text_test" ? (
                          <TRIcon
                            style={{
                              height: "20px",
                              width: "20px",
                              stroke: test[0]?.completed
                                ? "#808191"
                                : "#96999C",
                            }}
                          />
                        ) : test[0]?.test_type === "case_study_test" ? (
                          <CSIcon
                            style={{
                              height: "20px",
                              width: "20px",
                              stroke: test[0]?.completed
                                ? "#808191"
                                : "#96999C",
                            }}
                          />
                        ) : test[0]?.test_type === "video_test" ? (
                          <VRIcon
                            style={{
                              height: "20px",
                              width: "20px",
                              stroke: test[0]?.completed
                                ? "#808191"
                                : "#96999C",
                            }}
                          />
                        ) : (
                          <FUIcon
                            style={{
                              height: "20px",
                              width: "20px",
                              stroke: test[0]?.completed
                                ? "#808191"
                                : "#96999C",
                            }}
                          />
                        )}
                        <span
                          className="body-1"
                          style={{
                            marginLeft: "10px",
                            color:
                              test[0]?.completed || test[0]?.prev_test_results
                                ? `${
                                    (test[0]?.test_type === "coding_test" &&
                                      codingScore === null) ||
                                    (test[0]?.test_type ===
                                      "personality_test" &&
                                      !assessment?.personality_test_result)
                                      ? "#808191"
                                      : "#121216"
                                  }`
                                : "#808191",
                          }}
                        >
                          {test[0]?.test_type === "coding_test"
                            ? "Coding Test"
                            : test[0]?.test_type === "skill_test"
                            ? "Skill Based Test"
                            : test[0]?.test_type === "value_test"
                            ? "Values Fitness Test"
                            : test[0]?.test_type === "personality_test"
                            ? "Personality Test"
                            : test[0]?.test_type === "text_test"
                            ? "Text Responses"
                            : test[0]?.test_type === "case_study_test"
                            ? "Case Study"
                            : test[0]?.test_type === "video_test"
                            ? "Video Responses"
                            : "File Upload"}
                        </span>
                      </div>
                      {test[0]?.completed ? (
                        <>
                          {(test[0]?.test_type === "coding_test" &&
                            codingScore === null) ||
                          (test[0]?.test_type === "personality_test" &&
                            !assessment?.personality_test_result) ? (
                            <div
                              className="candidate-status-container dark-100"
                              style={{
                                marginLeft: isOnMobileScreen && "24px",
                                border: "1px solid #121216",
                              }}
                            >
                              {test[0]?.test_type === "personality_test"
                                ? "Under Evaluation"
                                : "Pending"}
                            </div>
                          ) : (
                            <ArrowIcon
                              className={`arrow ${isExpand && "roate-arrow"}`}
                            />
                          )}
                        </>
                      ) : test[0]?.prev_test_results ? (
                        <ArrowIcon
                          className={`arrow ${isExpand && "roate-arrow"}`}
                        />
                      ) : assessment?.status === "EXPIRED" ? (
                        <div
                          className="candidate-status-container dark-100"
                          style={{
                            marginLeft: isOnMobileScreen && "24px",
                            border: "1px solid #121216",
                          }}
                        >
                          Expired
                        </div>
                      ) : (
                        <div
                          className="candidate-status-container dark-100"
                          style={{
                            marginLeft: isOnMobileScreen && "24px",
                            border: "1px solid #121216",
                          }}
                        >
                           {test[0]?.test_type === "personality_test"
                                ? "Attempt Pending"
                                : "Pending"}
                        </div>
                      )}
                    </div>
                    {(test[0]?.completed || test[0]?.prev_test_results) && (
                      <div className={`d-flex align-items-start flex-column`}>
                        {(test[0]?.test_type === "coding_test" ||
                          test[0]?.test_type === "skill_test" ||
                          test[0]?.test_type === "value_test") && (
                          <span
                            className="body-3 dark-50"
                            style={{ marginRight: "15px" }}
                          >
                            {test.every(
                              (t) => t?.completed || t?.prev_test_results
                            ) && (
                              <>
                                Total Score:{" "}
                                <span className="dark-100">
                                  {test[0]?.test_type === "value_test" ? (
                                    <>
                                      {test[0]?.test_results
                                        ? `${parseFloat(
                                            calculateScore(
                                              test[0]?.test_results
                                            )
                                          ).toFixed(1)}`
                                        : "0"}
                                    </>
                                  ) : (
                                    <>
                                      {`${parseFloat(
                                        calculateScore(test)
                                      ).toFixed(1)}`}
                                    </>
                                  )}
                                  /10
                                </span>
                              </>
                            )}
                          </span>
                        )}
                        {test[0]?.test_type !== "upload_test" &&
                          test[0]?.test_type !== "video_test" &&
                          test[0]?.test_type !== "personality_test" && (
                            <div className="d-flex">
                              {test.every((t) => t?.prev_test_results) ? (
                                <></>
                              ) : test[0]?.test_type === "value_test" ? (
                                <>
                                  {test[0]?.test_type === "value_test" &&
                                  !test[0]?.finished_in ? (
                                    <></>
                                  ) : (
                                    <span
                                      className="body-3 dark-50"
                                      style={{ marginRight: "15px" }}
                                    >
                                      Finished in:{" "}
                                      <span className="dark-100">
                                        {test.every(
                                          (t) =>
                                            t?.completed || t?.prev_test_results
                                        )
                                          ? calculateFinshedInTime(test)
                                          : "not finished yet"}
                                      </span>
                                    </span>
                                  )}
                                </>
                              ) : (
                                <span
                                  className="body-3 dark-50"
                                  style={{ marginRight: "15px" }}
                                >
                                  Finished in:{" "}
                                  <span className="dark-100">
                                    {test.every(
                                      (t) =>
                                        t?.completed || t?.prev_test_results
                                    )
                                      ? calculateFinshedInTime(test)
                                      : "not finished yet"}
                                  </span>
                                </span>
                              )}
                            </div>
                          )}
                      </div>
                    )}
                  </>
                )}
              </div>
              {/* {(test[0]?.completed) ?
								<>
									{((test[0]?.test_type === 'coding_test' && codingScore === null) || (test[0]?.test_type === 'personality_test' && !assessment?.personality_test_result)) &&
										<div className='candidate-status-container dark-100' style={{border: '1px solid #121216'}}>Pending</div>
									}
								</>
								:
								assessment?.status === 'EXPIRED' ?
								<div className='candidate-status-container dark-100' style={{border: '1px solid #121216'}}>Expired</div>
								:
								<div className='candidate-status-container dark-100' style={{border: '1px solid #121216'}}>Pending</div>
							} */}
            </div>
          </CustomTooltip>
          {
            <div
              className={`assessment-result-view ${
                isExpand && "assessment-result-view-onExpand"
              }`}
            >
              {test.length > 0 &&
                test.map((_eachTest, index) => {
                  return (
                    <>
                      {_eachTest?.test_type === "coding_test" ? (
                        <CustomTooltip
                          varrient="result-sidebar-tooltip"
                          customPosition={{ top: "-90px" }}
                          text={
                            _eachTest?.completed
                              ? `${
                                  _eachTest?.score === null &&
                                  !_eachTest?.prev_test_results
                                    ? "Evaluation in progress, please check back later"
                                    : ""
                                }`
                              : assessment?.status === "EXPIRED"
                              ? "Test expired"
                              : _eachTest?.prev_test_results
                              ? ""
                              : "Test not completed yet"
                          }
                        >
                          <div
                            className="test_result_card"
                            style={{
                              marginTop: index !== 0 ? "16px" : "0px",
                              background:
                                !_eachTest?.completed &&
                                !_eachTest?.prev_test_results
                                  ? "#ECEDF1"
                                  : _eachTest?.completed &&
                                    _eachTest?.score === null &&
                                    !_eachTest?.prev_test_results &&
                                    "#ECEDF1",
                            }}
                          >
                            <div
                              className="result_card-left-side"
                              style={{ gap: "8px" }}
                            >
                              <img
                                src={_eachTest.icon}
                                style={{
                                  width: "16.8px",
                                  height: "16.8px",
                                  marginRight: "12px",
                                }}
                              />
                              <div className="d-flex flex-column align-items-start">
                                <span
                                  className="body-2 dark-100 coding-title-truncate"
                                  style={{
                                    maxWidth:
                                      auth?.data?.role?.name ===
                                        UserRole.CANDIDATE && "250px",
                                  }}
                                >
                                  {_eachTest?.title}
                                </span>
                                {/* <span
																	className='body-3 primary-text'
																	style={{ marginTop: '-4px' }}
																>
																	{_eachTest?.difficulty}
																</span> */}
                              </div>
                              {_eachTest?.prev_test_results && (
                                <CustomTooltip
                                  varrient="result-sidebar-tooltip"
                                  customPosition={{ top: "-90px" }}
                                  text={`${
                                    auth?.data?.role?.name ===
                                    UserRole.CANDIDATE
                                      ? "You have chosen to proceed with the test results from"
                                      : "The candidate has chosen to proceed with the test results from"
                                  } ${new Date(
                                    _eachTest?.prev_test_results?.attempted_at
                                  ).toDateString()}.`}
                                >
                                  <ClockIcon />
                                </CustomTooltip>
                              )}
                            </div>
                            <div className="result_card-right-side">
                              {(auth?.data?.role?.name !== UserRole.CANDIDATE ||
                                (auth?.data?.role?.name ===
                                  UserRole.CANDIDATE &&
                                  isForPublic)) &&
                                ((_eachTest?.completed &&
                                  _eachTest?.score !== null) ||
                                  _eachTest?.prev_test_results) && (
                                  <span
                                    className="body-2 dark-100 pointer"
                                    style={{
                                      width: "80px",
                                      textDecoration: "underline",
                                    }}
                                    onClick={() => {
                                      isForPublic
                                        ? viewPublicTestDetail(_eachTest)
                                        : viewTestDetail(_eachTest);
                                    }}
                                  >
                                    View Details
                                  </span>
                                )}
                              {auth?.data?.role?.name === UserRole.CANDIDATE &&
                                !isForPublic &&
                                _eachTest?.prev_test_results && (
                                  <span
                                    className="body-2 dark-100 pointer"
                                    style={{
                                      width: "80px",
                                      textDecoration: "underline",
                                    }}
                                    onClick={() => {
                                      viewTestHistory(_eachTest);
                                    }}
                                  >
                                    View Test
                                  </span>
                                )}
                              <span className="body-2 primary-dark-text">
                                {(_eachTest?.completed &&
                                  _eachTest?.score !== null) ||
                                _eachTest?.prev_test_results
                                  ? parseFloat(
                                      (_eachTest?.prev_test_results
                                        ? _eachTest?.prev_test_results
                                            ?.score_object?.score
                                        : _eachTest?.score) * 10
                                    ).toFixed(1) || 0
                                  : "--"}
                                <span className="dark-50">/10</span>
                              </span>
                            </div>
                          </div>
                        </CustomTooltip>
                      ) : _eachTest?.test_type === "skill_test" ? (
                        <CustomTooltip
                          varrient="result-sidebar-tooltip"
                          customPosition={{ top: "-90px" }}
                          text={
                            _eachTest?.completed || _eachTest?.prev_test_results
                              ? ""
                              : assessment?.status === "EXPIRED"
                              ? "Test expired"
                              : "Test not completed yet"
                          }
                        >
                          <div
                            className="test_result_card"
                            style={{
                              marginTop: index !== 0 ? "16px" : "0px",
                              background:
                                !_eachTest?.completed &&
                                !_eachTest?.prev_test_results &&
                                "#ECEDF1",
                            }}
                          >
                            <div
                              className="result_card-left-side"
                              style={{ gap: "8px" }}
                            >
                              <div className="d-flex flex-column align-items-start">
                                <span
                                  className="body-2 dark-100 coding-title-truncate"
                                  style={{
                                    maxWidth:
                                      auth?.data?.role?.name ===
                                        UserRole.CANDIDATE && "250px",
                                  }}
                                >
                                  {_eachTest?.title}
                                </span>
                                {/* <span className='body-3 primary-text'>
																	{_eachTest?.difficulty}
																</span> */}
                              </div>
                              {_eachTest?.prev_test_results && (
                                <CustomTooltip
                                  varrient="result-sidebar-tooltip"
                                  customPosition={{ top: "-90px" }}
                                  text={`${
                                    auth?.data?.role?.name ===
                                    UserRole.CANDIDATE
                                      ? "You have chosen to proceed with the test results from"
                                      : "The candidate has chosen to proceed with the test results from"
                                  } ${new Date(
                                    _eachTest?.prev_test_results?.attempted_at
                                  ).toDateString()}.`}
                                >
                                  <ClockIcon />
                                </CustomTooltip>
                              )}
                            </div>
                            <div className="result_card-right-side">
                              {auth?.data?.role?.name === UserRole.CANDIDATE &&
                                _eachTest?.prev_test_results && (
                                  <span
                                    className="body-2 dark-100 pointer"
                                    style={{
                                      width: "80px",
                                      textDecoration: "underline",
                                    }}
                                    onClick={() => {
                                      viewTestHistory(_eachTest);
                                    }}
                                  >
                                    View Test
                                  </span>
                                )}
                              <span className="body-2 primary-dark-text">
                                {_eachTest?.completed
                                  ? parseFloat(
                                      _eachTest?.prev_test_results
                                        ? (10 /
                                            _eachTest?.prev_test_results
                                              ?.score_object?.total_questions) *
                                            _eachTest?.prev_test_results
                                              ?.score_object?.correct_answers
                                        : (10 / _eachTest?.total_questions) *
                                            _eachTest?.correct_answers
                                    ).toFixed(1)
                                  : _eachTest?.prev_test_results
                                  ? parseFloat(
                                      (10 /
                                        _eachTest?.prev_test_results
                                          ?.score_object?.total_questions) *
                                        _eachTest?.prev_test_results
                                          ?.score_object?.correct_answers
                                    ).toFixed(1)
                                  : "--"}
                                <span className="dark-50">/10</span>
                              </span>
                            </div>
                          </div>
                        </CustomTooltip>
                      ) : _eachTest?.test_type === "value_test" ? (
                        <>
                          {_eachTest?.test_results?.map((test_result, _) => {
                            return (
                              <div
                                key={_}
                                className="test_result_card"
                                style={{ marginTop: _ !== 0 ? "16px" : "0px" }}
                              >
                                <div
                                  className="result_card-left-side"
                                  style={{ gap: "8px" }}
                                >
                                  <span
                                    className="body-2 dark-100 coding-title-truncate"
                                    style={{
                                      maxWidth:
                                        auth?.data?.role?.names ===
                                          UserRole.CANDIDATE && "250px",
                                    }}
                                  >
                                    {test_result.title}
                                  </span>
                                  {test_result?.test_result_id && (
                                    <CustomTooltip
                                      varrient="result-sidebar-tooltip"
                                      customPosition={{ top: "-90px" }}
                                      text={`Test results from ${new Date(
                                        test_result?.attempted_at
                                      ).toDateString()} were automatically used.`}
                                    >
                                      <ClockIcon />
                                    </CustomTooltip>
                                  )}
                                </div>
                                <div className="result_card-right-side">
                                  <span className="body-2 primary-dark-text">
                                    {_eachTest?.completed
                                      ? parseFloat(
                                          (10 / test_result?.total_questions) *
                                            test_result?.correct_answers
                                        ).toFixed(1)
                                      : "--"}
                                    <span className="dark-50">/10</span>
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : _eachTest?.test_type === "personality_test" ? (
                        <>
                          <div className="test_result_card">
                            <div
                              className="result_card-left-side"
                              style={{ gap: "8px" }}
                            >
                              <div className="d-flex flex-column align-items-start">
                                {assessment?.personality_test_result?.error
                                  ?.message ? (
                                  <span
                                    className="body-2 danger-text coding-title-truncate"
                                    style={{
                                      maxWidth:
                                        auth?.data?.role?.names ===
                                          UserRole.CANDIDATE && "250px",
                                    }}
                                  >
                                    {`Failed: Invalid Content`}
                                  </span>
                                ) : (
                                  <span
                                    className="body-2 dark-100 coding-title-truncate"
                                    style={{
                                      maxWidth:
                                        auth?.data?.role?.name ===
                                          UserRole.CANDIDATE && "250px",
                                    }}
                                  >
                                    {`${assessment?.personality_test_result?.pers_type} (${personalityType?.pt_alias})`}
                                  </span>
                                )}
                                {/* <span
																	className='body-3 primary-text'
																	style={{ marginTop: '-4px' }}
																>
																	Personality Type
																</span> */}
                              </div>
                              {_eachTest?.test_result_id && (
                                <CustomTooltip
                                  varrient="result-sidebar-tooltip"
                                  customPosition={{ top: "-90px" }}
                                  text={`Test results from ${new Date(
                                    assessment?.personality_test_result?.attempted_at
                                  ).toDateString()} were automatically used.`}
                                >
                                  <ClockIcon />
                                </CustomTooltip>
                              )}
                            </div>
                            {(auth?.data?.role?.name !== UserRole.CANDIDATE ||
                              (auth?.data?.role?.name === UserRole.CANDIDATE &&
                                isForPublic)) &&
                              _eachTest?.completed && (
                                <div
                                  className="result_card-right-side"
                                  style={{ width: "180px" }}
                                >
                                  <span
                                    className="body-3 dark-100 pointer"
                                    style={{ textDecoration: "underline" }}
                                    onClick={() => {
                                      assessment?.personality_test_result
                                        ? setCommonValues({
                                            ...commonValues,
                                            detailedTestView: {
                                              isExpanded: true,
                                              testType: "personality_response",
                                              data: null,
                                            },
                                          })
                                        : {};
                                    }}
                                  >
                                    View Response
                                  </span>
                                  <span
                                    className="body-3 dark-100 pointer"
                                    style={{ textDecoration: "underline" }}
                                    onClick={() => {
                                      assessment?.personality_test_result
                                        ? setCommonValues({
                                            ...commonValues,
                                            detailedTestView: {
                                              isExpanded: true,
                                              testType: "personality_insight",
                                              data: null,
                                            },
                                          })
                                        : {};
                                    }}
                                  >
                                    View Details
                                  </span>
                                </div>
                              )}
                          </div>
                        </>
                      ) : _eachTest?.test_type === "upload_test" ? (
                        <>
                          {responses.map((response, resIndex) => {
                            return (
                              <div
                                key={resIndex}
                                className="file-upload-result"
                                style={{
                                  marginTop: resIndex !== 0 ? "16px" : "0px",
                                  gap: "8px",
                                }}
                              >
                                <span className="question-ans-text-heading">
                                  Statement
                                </span>
                                <span className="question-text">
                                  {response.text}
                                </span>
                                <span className="question-ans-text-heading">
                                  Attachment
                                </span>
                                {response.status === "SKIPPED" ? (
                                  <span
                                    className="body-2 danger-text"
                                    style={{ textAlign: "left" }}
                                  >
                                    No File Attached! The question was skipped.
                                  </span>
                                ) : (
                                  <TestDetailsUploadResponse
                                    response={response}
                                    assessment={assessment}
                                    isForPublic={isForPublic}
                                    test_type={_eachTest?.test_type}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </>
                      ) : _eachTest?.test_type === "text_test" ? (
                        <>
                          {responses.map((response, resIndex) => {
                            return (
                              <div
                                key={resIndex}
                                className="file-upload-result"
                                style={{
                                  marginTop: resIndex !== 0 ? "16px" : "0px",
                                  gap: "8px",
                                }}
                              >
                                <span
                                  className="question-ans-text-heading body-2"
                                  style={{}}
                                >{`Question ${resIndex + 1}`}</span>
                                <span className="question-text">
                                  {response.text}
                                </span>
                                <span className="question-ans-text-heading">
                                  Answer
                                </span>
                                {response.status === "EXPIRED" ? (
                                  <span
                                    className="body-2 danger-text"
                                    style={{ textAlign: "left" }}
                                  >
                                    {" "}
                                    No Answer! The question was Expired.
                                  </span>
                                ) : response.status === "SKIPPED" ? (
                                  <span
                                    className="body-2 danger-text"
                                    style={{ textAlign: "left" }}
                                  >
                                    {" "}
                                    No Answer! The question was skipped.
                                  </span>
                                ) : (
                                  <>
                                    {response.response_text && (
                                      <span className="answer-text">
                                        {response.response_text}
                                      </span>
                                    )}
                                    {response.resource_id && (
                                      <>
                                        <span className="question-ans-text-heading">
                                          Attachment
                                        </span>
                                        <TestDetailsUploadResponse
                                          response={response}
                                          assessment={assessment}
                                          isForPublic={isForPublic}
                                          test_type={_eachTest?.test_type}
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            );
                          })}
                        </>
                      ) : _eachTest?.test_type === "video_test" ? (
                        <>
                          {responses.map((response, resIndex) => {
                            return (
                              <div
                                key={resIndex}
                                className="file-upload-result"
                                style={{
                                  marginTop: resIndex !== 0 ? "16px" : "0px",
                                  gap: "8px",
                                }}
                              >
                                <span className="question-ans-text-heading">
                                  Statement{" "}
                                </span>
                                <span className="question-text">
                                  {response.text}
                                </span>
                                <span className="question-ans-text-heading">
                                  Uploaded Video
                                </span>
                                {response.status === "SKIPPED" ? (
                                  <span
                                    className="body-2 danger-text"
                                    style={{ textAlign: "left" }}
                                  >
                                    No Video Attachment! The question was
                                    skipped.
                                  </span>
                                ) : (
                                  <TestDetailsUploadResponse
                                    response={response}
                                    assessment={assessment}
                                    isForPublic={isForPublic}
                                    test_type={_eachTest?.test_type}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </>
                      ) : _eachTest?.test_type === TestType.CASE_STUDY_TEST ? (
                        <>
                          {(auth?.data?.role?.name !== UserRole.CANDIDATE ||
                            (auth?.data?.role?.name === UserRole.CANDIDATE &&
                              isForPublic)) && (
                            <span
                              className="body-2 dark-100 pointer"
                              style={{
                                padding: "16px",
                                float: "right",
                                textDecoration: "underline",
                                marginBottom: "16px",
                              }}
                              onClick={() => {
                                setCommonValues({
                                  ...commonValues,
                                  detailedTestView: {
                                    isExpanded: true,
                                    testType: "case_study_test",
                                    data: null,
                                  },
                                });
                              }}
                            >
                              View Question Statement
                            </span>
                          )}
                          {responses.map((response, resIndex) => {
                            return (
                              <div
                                key={resIndex}
                                className="file-upload-result"
                                style={{
                                  marginTop: resIndex !== 0 ? "16px" : "0px",
                                }}
                              >
                                <span
                                  className="body-2 dark-50"
                                  style={{ textAlign: "left" }}
                                >{`Question ${resIndex + 1} Response`}</span>
                                <span
                                  className="body-2 black-700"
                                  style={{
                                    textAlign: "left",
                                    marginTop: "8px",
                                    width: "100%",
                                  }}
                                >
                                  {response.type ===
                                    CaseStudyQuestionTypes.TEXT_RESPONSE && (
                                    <>
                                      {response.status === "SKIPPED" ? (
                                        <span
                                          className="body-2 danger-text"
                                          style={{
                                            textAlign: "left",
                                            marginTop: "8px",
                                          }}
                                        >
                                          No Answer! The question was skipped.
                                        </span>
                                      ) : (
                                        <ReactQuill
                                          theme="bubble"
                                          readOnly
                                          value={response.response_text}
                                        />
                                      )}
                                    </>
                                  )}

                                  {response.type ===
                                    CaseStudyQuestionTypes.UPLOAD_RESPONSE && (
                                    <div className="d-flex flex-column">
                                      <span
                                        className="body-2 primary-text"
                                        style={{
                                          textAlign: "left",
                                          marginTop: "8px",
                                          marginBottom: "8px",
                                        }}
                                      >
                                        File Attachment
                                      </span>
                                      <>
                                        {response.status === "SKIPPED" ? (
                                          <span
                                            className="body-2 danger-text"
                                            style={{
                                              textAlign: "left",
                                              marginTop: "8px",
                                            }}
                                          >
                                            No File Attached! The question was
                                            skipped.
                                          </span>
                                        ) : (
                                          <>
                                            <TestDetailsUploadResponse
                                              response={response}
                                              assessment={assessment}
                                              isForPublic={isForPublic}
                                              test_type={response.type}
                                            />
                                          </>
                                        )}
                                      </>
                                    </div>
                                  )}
                                  {response.type ===
                                    CaseStudyQuestionTypes.VIDEO_RESPONSE && (
                                    <div className="d-flex flex-column">
                                      <span
                                        className="body-2"
                                        style={{
                                          textAlign: "left",
                                          fontWeight: "500",
                                          marginTop: "8px",
                                          marginBottom: "8px",
                                          color: "#6F2DBD",
                                        }}
                                      >
                                        Image/ video attachment
                                      </span>
                                      <>
                                        {response.status === "SKIPPED" ? (
                                          <span
                                            className="body-2 danger-text"
                                            style={{
                                              textAlign: "left",
                                              marginTop: "8px",
                                            }}
                                          >
                                            No Image/ Video Attachment! The
                                            question was skipped.
                                          </span>
                                        ) : (
                                          <TestDetailsUploadResponse
                                            response={response}
                                            assessment={assessment}
                                            isForPublic={isForPublic}
                                            test_type={response.type}
                                          />
                                        )}
                                      </>
                                    </div>
                                  )}
                                </span>
                              </div>
                            );
                          })}
                        </>
                      ) : null}
                    </>
                  );
                })}
            </div>
          }
        </div>
      }
    </>
  );
}
