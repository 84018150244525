import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import AlertBox from "../components/common/AlertBox";
import OnboardingBox from "../components/OnboardingBox";
import Input from "../components/sub-component/Input";
import Button from "../components/sub-component/Button";
import { candidateActivation } from "../redux/thunks/Auth";
import { getAssessment, takeOverSession } from "../redux/thunks/Assessment";
import { PathName } from "../utils/routes";
import { AssessmentStatus } from "../utils/Constants";
import { getUserProfile, updateUserProfile } from "../redux/thunks/UserProfile";
import ProcessingIndicator from "../components/common/ProcessingIndicator";

function CandidateNameForm() {
  const history = useHistory();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [processing, setProcessing] = useState(false);
  const [isloading, setIsLoading] = useState(true);

  const [alert, setAlert] = useState(null);

  const dispatch = useDispatch();
  const isOnTabletScreen = useMediaQuery(
    "(min-width:768px) and (max-width:1279px)"
  );
  const isOnMobileScreen = useMediaQuery("(max-width: 767px)");

  const handleChange = (name, value) => {
    if (name === "firstName") {
      setFirstName(value);
    } else if (name === "lastName") {
      setLastName(value);
    }
  };
  const assessment = useSelector((state) => state.assessmentReducer);

  
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("invite_token");
    const invite_token = searchParams.get("invite_token");
    const assess_id = searchParams.get("assess_id");

    const fetchData = async (token) => {
      try {
        let accessToken = null;
        const disptached1 = await dispatch(
          candidateActivation({
            activation_token: token,
            route: `/resend-link?invite_token=${invite_token}&assess_id=${assess_id}`,
          })
        );
        accessToken = disptached1.payload.data.access_token;
        const test = await dispatch(
          getUserProfile({ access_token: accessToken })
        );

        if (
          test?.payload?.first_name !== "" &&
          test?.payload?.last_name !== ""
        ) {
          window.location.replace(`/dashboard/assessments/${assess_id}`);
          const getAssess = await dispatch(getAssessment({ id: assess_id }));
          if (getAssess?.payload?.status === AssessmentStatus.FINISHED) {
            window.location.replace(`/dashboard/assessments/${assess_id}`);
          } else {
            //
          }
        } else {
          //
        }
      } catch (error) {
        if (error?.message === "Invalid Token") {
          window.location.replace(
            `/resend-link?invite_token=${invite_token}&assess_id=${assess_id}`
          );
        }
      }
    };
    if (token) {
      fetchData(token);
    } else {
      // window.location.replace(PathName.UNAVAILABLE_ASSESSMENT);
    }
  }, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 4000);

    return () => clearTimeout(timeout);
  }, []);

  const handleSubmit = async (e, accessToken) => {
    setProcessing(true);
    const searchParams = new URLSearchParams(window.location.search);
    const assess_id = searchParams.get("assess_id");
    e.preventDefault();
    try {
      const dispatched = await dispatch(
        updateUserProfile({
          first_name: firstName,
          last_name: lastName,
          access_token: accessToken,
        })
      );
      const dispatched1 = await dispatch(
        takeOverSession({ assess_id: assess_id })
      );
      const errorMessage = dispatched1?.error?.message;
      if (errorMessage === "Rejected") {
        window.location.replace("/unavailable-assessment");
        return;
      }
      setAlert(
        <AlertBox
          description="Profile updated successfully!"
          color="success"
          title="Success"
          onCloseAlert={() => {
            setAlert(null);
          }}
        />
      );
      setProcessing(false);
      window.location.replace(`/dashboard/assessments/${assess_id}`);
    } catch (error) {
      setAlert(
        <AlertBox
          description={`Error occurred: ${error.message}`}
          color="danger"
          title="Error"
          onCloseAlert={() => {
            setAlert(null);
          }}
        />
      );
      setProcessing(false);
    }
    window.location.reload();
  };

  return (
    <>
      {isloading ? (
        <>
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <div
                className="processing-indicator primary"
                style={{ width: "40px", height: "40px" }}
              />
            </div>
          </div>
        </>
      ) : (
        <OnboardingBox>
          <div className="d-flex flex-column login-form-container">
            <div className="form">
              <div
                className={`${
                  isOnMobileScreen || isOnTabletScreen
                    ? "headline-5"
                    : "headline-3"
                } dark-100`}
              >
                Enter Your Name
              </div>
              <div
                className={`body-${
                  isOnMobileScreen || isOnTabletScreen ? "1" : "2"
                } dark-50`}
                style={{ marginTop: "10px" }}
              >
                {`Please enter your first and last name to personalize your invitation.`}
              </div>
              <div>
                <form onSubmit={handleSubmit}>
                  <div className="login-input-group">
                    <div className="d-flex flex-column mt-4">
                      <label
                        className="body-2 dark-50 mb-2"
                        style={{ marginLeft: "24px" }}
                      >
                        First Name
                      </label>

                      <Input
                        // placeholder="First Name"
                        onChange={(e) =>
                          handleChange("firstName", e.target.value)
                        }
                        type={"text"}
                        value={firstName}
                        onBoardingInput
                        varrient={"primary"}
                      />
                    </div>
                    <div className="d-flex flex-column mt-4">
                      <label
                        className="body-2 dark-50 mb-2"
                        style={{ marginLeft: "24px" }}
                      >
                        Last Name
                      </label>

                      <Input
                        // placeholder="Last Name"
                        onChange={(e) =>
                          handleChange("lastName", e.target.value)
                        }
                        type={"text"}
                        value={lastName}
                        onBoardingInput
                        varrient={"primary"}
                      />
                    </div>
                    <div className="mt-4">{alert}</div>
                    <div className="">
                      <Button
                        themeRectangleButton
                        processing={processing}
                        type={"submit"}
                        title={"Start Assessment"}
                        style={{ height: "40px", marginTop: "0px" }}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </OnboardingBox>
      )}
    </>
  );
}

export default CandidateNameForm;
