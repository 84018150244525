// import moment from 'moment';
import React, { useState, useEffect, useMemo } from 'react';
// import '../styles/table.css';
import { ReactComponent as ArrowUp } from '../../assets/icons/components/common/table/chevron-up-1.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/components/common/table/chevron-down-1.svg';
import { ReactComponent as BackArrow } from '../../assets/icons/Arrows/CaretLeft.svg';
import { ReactComponent as ForwardArrow } from '../../assets/icons/Arrows/CaretRight.svg';
import { ReactComponent as Filter } from '../../assets/icons/components/common/table/filter1.svg';
import { ReactComponent as Search } from '../../assets/icons/Views/candidate/assessments/search.svg';
import { ReactComponent as Shortlist } from '../../assets/icons/Views/dashboard/test-detail/user-check.svg';
import { ReactComponent as Reject } from '../../assets/icons/Views/dashboard/test-detail/user-minus.svg';
import Select from '../sub-component/Select';
import Checkbox from '../sub-component/Checkbox';
import Pagination from '../sub-component/Pagination';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomTooltip from './CustomTooltip';
import Input from '../sub-component/Input';
import { useFocusWithin } from '@react-aria/interactions';
import { utcFormatDate, formatDate, timeDifference } from '../../utils/utilities';
import ProcessingIndicator from '../common/ProcessingIndicator';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function Table(props) {

	const { data, customSorting,setHighlightActiveRow, searchColumns, testDetails, children, setData, selectedId, customSearch, backendFilter, setSelectedsortOption, selectedsortOption, onSelectRow, assessSpecRowClick } = props;
	const history = useHistory();

	const [sorting, setSorting] = useState({
		header: null,
		column: null,
		order: 'none',
		clickCount: 0
	});
	const [searchItem, setSearchItem] = useState('');
	const [openFilterCard, setOpenFilterCard] = useState(false);
	const [headers, setHeaders] = useState(props?.headers);
	const [headerLength, setheaderLength] = useState(props?.headers?.length);
	const [headerCheckboxState, setHeaderCheckboxState] = useState('');

	const [filteredData, setFilteredData] = useState([]);
	const [headerFilters, setHeaderFilters] = useState({});
	const [filterDropdowns, setFilterDropdowns] = useState('');

	const [rows, setRows] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	
	
	const [totalPages, setTotalPages] = useState(1);

	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [start, setStart] = useState(0);
	const [Finish, setFinish] = useState(10);
	const [slideIndex, setSlideIndex] = useState(1);
	const [filteredRow, setFilteredRow] = useState([]);
	const [isTableSearchInputOpen, setIsTableSearchInputOpen] = useState(false);

	const [selectedRow, setSelectedRow] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [activeRow, setActiveRow] = useState(-1);

	const isOnWebScreen = useMediaQuery('(min-width:1280px)');
	const isOnTabletScreen = useMediaQuery('(min-width:768px) and (max-width:1279px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');

	Array.prototype.sortBy = function (p, type, requirement) {
		if (type === 'string') {
			return this.slice(0).sort(function (a, b) {
				const StringA = a[p] ? a[p].toLowerCase() : '';
				const StringB = b[p] ? b[p].toLowerCase() : '';
				return (StringA > StringB) ? 1 : (StringA < StringB) ? -1 : 0;
			});
		}
		else if (type === 'number') {
			return this.slice(0).sort(function (a, b) {
				const numberA = isNaN(parseInt(a[p])) ? 0 : parseInt(a[p].toString().replaceAll(':', '').replaceAll('-', ''));
				const numberB = isNaN(parseInt(b[p])) ? 0 : parseInt(b[p].toString().replaceAll(':', '').replaceAll('-', ''));
				return (numberA > numberB) ? 1 : (numberA < numberB) ? -1 : 0;
			});
		}
		// else if (type === 'date') {
		// 	if (requirement === 'formatDate') {
		// 		return this.slice(0).sort(function (a, b) {
		// 			const dateA = isNaN(new Date(moment(a[p], 'DD/MM/YYYY')).getTime()) ? 0 : new Date(moment(a[p], 'DD/MM/YYYY')).getTime();
		// 			const dateB = isNaN(new Date(moment(b[p], 'DD/MM/YYYY')).getTime()) ? 0 : new Date(moment(b[p], 'DD/MM/YYYY')).getTime();
		// 			return (dateA > dateB) ? 1 : (dateA < dateB) ? -1 : 0;
		// 		});
		// 	}
		// 	else {
		// 		return this.slice(0).sort(function (a, b) {
		// 			const dateA = isNaN(new Date(a[p]).getTime()) ? 0 : new Date(a[p]).getTime();
		// 			const dateB = isNaN(new Date(b[p]).getTime()) ? 0 : new Date(b[p]).getTime();
		// 			return (dateA > dateB) ? 1 : (dateA < dateB) ? -1 : 0;
		// 		});
		// 	}
		// }
	};

	Array.prototype.sortByDec = function (p, type, requirement) {
		if (type === 'string') {
			return this.slice(0).sort(function (a, b) {
				const StringA = a[p] ? a[p].toLowerCase() : '';
				const StringB = b[p] ? b[p].toLowerCase() : '';
				return (StringA < StringB) ? 1 : (StringA > StringB) ? -1 : 0;
			});
		}
		else if (type === 'number') {
			return this.slice(0).sort(function (a, b) {
				const numberA = isNaN(parseInt(a[p])) ? 0 : parseInt(a[p].toString().replaceAll(':', '').replaceAll('-', ''));
				const numberB = isNaN(parseInt(b[p])) ? 0 : parseInt(b[p].toString().replaceAll(':', '').replaceAll('-', ''));
				return (numberA < numberB) ? 1 : (numberA > numberB) ? -1 : 0;
			});
		}
		// else if (type === 'date') {
		// 	if (requirement === 'formatDate') {
		// 		return this.slice(0).sort(function (a, b) {
		// 			const dateA = isNaN(new Date(moment(a[p], 'DD/MM/YYYY')).getTime()) ? 0 : new Date(moment(a[p], 'DD/MM/YYYY')).getTime();
		// 			const dateB = isNaN(new Date(moment(b[p], 'DD/MM/YYYY')).getTime()) ? 0 : new Date(moment(b[p], 'DD/MM/YYYY')).getTime();
		// 			return (dateA < dateB) ? 1 : (dateA > dateB) ? -1 : 0;
		// 		});
		// 	}
		// 	else {
		// 		return this.slice(0).sort(function (a, b) {
		// 			const dateA = isNaN(new Date(a[p]).getTime()) ? 0 : new Date(a[p]).getTime();
		// 			const dateB = isNaN(new Date(b[p]).getTime()) ? 0 : new Date(b[p]).getTime();
		// 			return (dateA < dateB) ? 1 : (dateA > dateB) ? -1 : 0;
		// 		});
		// 	}
		// }
	};

	useEffect(() => {
		setRows([...data]);
		setFilteredRow([...data]);
	}, [data]);

	useEffect(() => {
		setHeaders(props?.headers);
	}, [props?.headers]);

	useEffect(() => {
		setFilteredRow(filteredData);
	}, [filteredData]);

	useEffect(() => {
		setRows(getCurrentPageData());
		const totalPages = Math.ceil(filteredRow?.length / rowsPerPage) || 0;
		setTotalPages(totalPages);
	}, [rowsPerPage, filteredRow]);

	useEffect(() => {
		if (currentPage) {
			setRows(getCurrentPageData());
		}
	}, [currentPage]);

	useEffect(() => {
		if (customSearch) {
			handleSearchChange(customSearch);
		}
	}, [customSearch]);

	const handlePageChange = (newPage) => {

		setCurrentPage(newPage);
	};

	useEffect(() => {
		if (selectedId) {
			const activeRowId = rows.findIndex(row => row.id === selectedId);
			setActiveRow(activeRowId);

			// if(!rows.find(row => row.id === selectedId) && currentPage < totalPages) {
			// 	setCurrentPage(prevPage => {
			// 		if(prevPage)
			// 	})
			// }
		}
	}, [selectedId, rows]);

	useEffect(() => {
		const headers = props?.headers;
		let _headerLength = props?.headers?.length;
		if (isOnTabletScreen) {
			_headerLength = headers.filter((header) => header.screens.includes('tab')).length;
		}
		if (isOnMobileScreen) {
			_headerLength = headers.filter((header) => header.screens.includes('mob')).length;
		}
		if (props.showActions) {
			headers.push({ name: 'Actions', type: 'actions', disableSorting: true });
			_headerLength += 1;
		}

		setheaderLength(_headerLength);

	}, [props?.headers, isOnTabletScreen, isOnMobileScreen]);

	const sortAssending = (byColumn) => {
		const { value, type, requirement } = byColumn;
		if (data) {
			var sortedObjs = [];
			sortedObjs = data.sortBy(value, type, requirement);
		}
		setFilteredRow(sortedObjs);
	};

	const handleSelectRow = (index) => {
		props.handleRowSelect(index, 'single');

		setHeaderCheckboxState(getCheckboxState(rows));
	};

	const selectedItemsCount = () => {
		let count = 0;
		filteredRow.forEach(item => {
			if (item.selected) {
				count += 1;
			}
		});
		return count;
	};

	const getCheckboxState = (items) => {
		const anySelected = items.length && items?.some(item => item.selected);
		const allSelected = items.length && items?.every(item => item.selected);

		if (anySelected && !allSelected) {
			return 'indeterminate';
		} else if (allSelected) {
			return 'checked';
		} else {
			return '';
		}
	};

	const handleSelectAll = () => {
		const selectedIds = rows?.map((row) => row.id);
		if (headerCheckboxState === 'checked') {
			props.handleRowSelect(selectedIds, headerCheckboxState, false);
		} else if (headerCheckboxState === 'indeterminate') {
			props.handleRowSelect(selectedIds, headerCheckboxState, false);
		} else {
			props.handleRowSelect(selectedIds, headerCheckboxState, true);
		}
		setHeaderCheckboxState(getCheckboxState(rows));
	};

	const handleBulkUpdate = async (hiringStage) => {
		const assessments = data.map((assesment) => assesment.selected ? {
			...assesment, hiringStage:
				`${hiringStage?.charAt(0).toUpperCase() + hiringStage?.toLowerCase()?.slice(1)}`, hiring_stage: hiringStage
		} : { ...assesment });
		const selectedIds = data.map((assesment) => assesment.selected && assesment.id).filter(item => item);
		props.handleBulkUpdate(selectedIds, hiringStage, assessments);
	};

	const sortDessending = (byColumn) => {
		const { value, type, requirement } = byColumn;
		if (data) {
			var sortedObjs = [];
			sortedObjs = data.sortByDec(value, type, requirement);
		}
		setFilteredRow(sortedObjs);
	};
	const getCurrentPageData = () => {
		const startIndex = (currentPage - 1) * rowsPerPage;
		let endIndex = startIndex + rowsPerPage;


		// Ensure endIndex doesn't exceed the length of the array
		endIndex = Math.min(endIndex, data?.length || 0);

		setHeaderCheckboxState(getCheckboxState(filteredRow?.slice(startIndex, endIndex) || []));

		return filteredRow?.slice(startIndex, endIndex) || [];
	};
	useEffect(() => {
		if (!rows.length && filteredData.length && currentPage > 1) {
			setCurrentPage(currentPage - 1);
		}
	}, [rows]);

	useEffect(() => {
		let filtered = [...data];
		Object.keys(headerFilters).forEach((columnName) => {
			const filterValue = headerFilters[columnName];
			if (filterValue) {
				filtered = filtered.filter(
					(item) => item[columnName].toString() === filterValue.value
				);
			}
		});

		if (searchItem) {
			filtered = filtered.filter((row) => {
				if (testDetails && row.user) {
					return searchColumns.some((col) => {
						return row.user[col]
							.toString()
							.toLowerCase()
							.includes(searchItem.toLowerCase());
					});
				} else {
					return searchColumns.some((col) => {
						if (col === 'hiringStage' && row[col] === 'Pending') {
							return 'Not Yet Evaluated'
								.toString()
								.toLowerCase()
								.includes(searchItem.toLowerCase());
						}
						return row[col] && row[col]
							.toString()
							.toLowerCase()
							.includes(searchItem.toLowerCase());
					});
				}
			});
		}

		if (sorting.order === 'none') {
			setFilteredData(filtered);
			setTimeout(() => {
				setLoading(false);
			}, 200);
		} else {
			filtered.sort((a, b) => {
				if (sorting.order === 'asc') {
					return customSorting[sorting?.header?.id](a[sorting?.header?.id], b[sorting?.header?.id]);
				} else {
					return customSorting[sorting?.header?.id](b[sorting?.header?.id], a[sorting?.header?.id]);
				}
			});
			setFilteredData(filtered);
			setTimeout(() => {
				setLoading(false);
			}, 200);
		}
		setOpenFilterCard(false);
	}, [data, headers, headerFilters, searchItem, sorting]);

	const handleFilterChange = (columnName, filterValue) => {
		setCurrentPage(1);
		setStart(0);
		setFinish(10);
		setHeaderFilters({
			...headerFilters,
			[columnName]: filterValue
		});
	};
	const handleResetFIlter = (columnName) => {
		setCurrentPage(1);
		setStart(0);
		setFinish(10);
		setHeaderFilters({
			...headerFilters,
			[columnName]: null
		});
		handleOpenFilterCard();
	};

	const handleSearchChange = (e) => {
		setCurrentPage(1);
		setStart(0);
		setFinish(10);
		setSearchItem(e.target.value);
	};

	const handleOpenFilterCard = (columnName) => {
		setFilterDropdowns(columnName);
		setOpenFilterCard(!openFilterCard);
	};

	const { focusWithinProps } = useFocusWithin({
		onFocusWithin: (e) => { },
		onBlurWithin: (e) => {
			setOpenFilterCard(false);
		}
	});

	const handleSort = (header) => {
		const currentSorting = { ...sorting };

		if (currentSorting.column === header?.name) {
			currentSorting.clickCount++;
			if (currentSorting.clickCount === 3) {
				currentSorting.header = null;
				currentSorting.column = null;
				currentSorting.order = 'none';
				currentSorting.clickCount = 0;
				setLoading(true);
			} else if (currentSorting.order === 'asc') {
				currentSorting.order = 'desc';
				setLoading(true);
			} else {
				currentSorting.order = 'asc';
				setLoading(true);
			}
		} else {
			currentSorting.header = header;
			currentSorting.column = header?.name;
			currentSorting.order = 'asc';
			currentSorting.clickCount = 1;
			setLoading(true);
		}

		setSorting(currentSorting);
		setCurrentPage(1);
		setStart(0);
		setFinish(10);
	};

	const nextSlide = () => {		
		const row = filteredRow.slice(start, Finish);
		if (data.length > 10) {
			setCurrentPage(currentPage + 1);
		}
		
		if (row.length < 10) {
			return;
		}
		if (slideIndex !== row.length) {
			setStart(start + 10);
			setFinish(Finish + 10);
			
		}
		else if (slideIndex === row.length) {
			setStart(0);
			setFinish(10);
		}
	};

	const prevSlide = () => {
		setCurrentPage(currentPage - 1);
		setStart(start - 10);
		setFinish(Finish - 10);
	};

	const handleBackendSort = (header) => {
		const currentSorting = { ...selectedsortOption };
		// console.log(selectedsortOption, header, 'testtt1');

		if (currentSorting.column === header?.id) {
			currentSorting.clickCount++;
			if (currentSorting.clickCount === 3) {
				currentSorting.column = header?.id;
				currentSorting.order = 'DESC';
				currentSorting.clickCount = 0;
			} else if (currentSorting.order === 'ASC') {
				currentSorting.order = 'none';
			} else {
				currentSorting.order = 'ASC';
			}
		} else {
			currentSorting.column = header?.id;
			currentSorting.order = 'ASC';
			currentSorting.clickCount = 1;
		}

		props.setCurrentPage(1);
		setSelectedsortOption(currentSorting);
	};

	useMemo(() => {
		setRows(filteredRow.slice(start, Finish));
	}, [filteredRow, start, Finish]);
	const handleRowClick = (row) => {		
		// e.preventDefault();
		history.push(`/dashboard/assessments/${row?.spec_id }?result=${row?.id}`);
 
	};
	

	return (
		<div className='w-100' style={{ background: 'white', borderRadius: '24px' }}>
			<div className='w-100 d-flex justify-content-start'>
				{props?.disableSearchInput ?
					<></>
					:
					<div className='table-search-container'>
						<div className='table-search-input'>
							<Search height={'24px'} width={'24px'} />
							<Input
								inputStyle={{ background: 'transparent', border: 'none' }}
								className={'w-100'}
								value={searchItem}
								placeholder={'Search ...'}
								onChange={handleSearchChange} />
						</div>
					</div>
				}
			</div>
			<div style={{ width: '100%', borderRadius: '24px' }} className="table-container">
				{['checked', 'indeterminate'].includes(headerCheckboxState) && props.enableCheckbox && <div className='bulk-update-header mt-3'>
					<div className='body-2'>{selectedItemsCount()} Items {!isOnMobileScreen ? 'Selected' : ''}</div>
					<div style={{ gap: '16px' }} className='body-2 d-flex'>
						<span style={{ gap: isOnMobileScreen ? '5px' : '10px', borderRadius: '24px', padding: '6px 12px', border: '1px solid #121216' }} className='d-flex align-items-center pointer' onClick={() => { handleBulkUpdate('REJECTED'); }}>Reject </span>
						<span style={{ gap: isOnMobileScreen ? '5px' : '10px', borderRadius: '24px', padding: '6px 12px', background: '#121216', color: 'white' }} className='d-flex align-items-center pointer' onClick={() => { handleBulkUpdate('SHORTLISTED'); }}>Shortlist</span>
					</div>
				</div>}
				<table style={{ width: '100%' }}>
					<thead>
						<tr>
							{
								headers && headers?.map((header, index) => {
									return (
										<React.Fragment key={index}>
											{
												((isOnWebScreen && header.screens.includes('web')) || (isOnTabletScreen && header.screens.includes('tab')) || (isOnMobileScreen && header.screens.includes('mob'))) &&
												< th
													className={`table-header  ${isOnWebScreen ? 'body-2' : 'body-3'}`}
													style={{ width: `${(props.showNo && index === 0) ? '50px' : `${100 / headerLength}%`}`, minWidth: `${(props.showNo && index === 0) ? '50px' : `${100 / headerLength}%`}`, maxWidth: `${(props.showNo && index === 0) ? '50px' : `${100 / headerLength}%`}`, textAlign: (props.showActions && header.name === 'Actions') ? 'center' : props?.textAlign ? props?.textAlign : 'space-between' }}
													key={index}
													tabIndex={-1} {...focusWithinProps}
												>
													<div className='d-flex justify-content-center align-items-center'>
														<div className='w-100 d-flex justify-content-start align-items-center  body-2' style={{ cursor: 'pointer' }} onClick={() => { !header.disableSorting && setFilteredRow(data); }}>
															{index === 0 && props.enableCheckbox && <div style={{ position: 'relative' }}>
																{headerCheckboxState === 'indeterminate' && <div className='indeterminate-checkbox' onClick={() => { handleSelectAll(); }} > <div className='indeterminate-icon'></div> </div>}
																<Checkbox checked={headerCheckboxState} onChange={() => { handleSelectAll(); }} primary />
															</div>}
															{header.enableFilter ?
																<div className={` ${headerFilters[header.filterName] ? ' table-header-selected-filter' : ''} d-flex justify-content-start align-items-center`} style={{ width: '100%', cursor: 'pointer', gap: '6px' }} onClick={() => { !header.disableSorting && setFilteredRow(data); }}>
																	<span className='body-2' style={{ color: headerFilters[header.filterName] ? 'white' : (header.enableFilter && openFilterCard && filterDropdowns === header.filterName) ? '#6F2DBD' : '#808191' }}>{header?.name}</span>
																	<>
																		{
																			headerFilters[header.filterName] &&
																			<div className='filter-option' >
																				<CustomTooltip customPosition={{ bottom: '12px', left: '0px' }} text={`${headerFilters[header.filterName].name} Filter is Applied`}>
																					<span className='body-3 text-center'>1</span>
																				</CustomTooltip>
																			</div>
																		}
																		<div className={`${' d-flex flex-column justify-content-center'}`} style={{ position: 'relative' }}>
																			<CustomTooltip customPosition={{ bottom: '55px', left: '8px' }} text={`Click to filter`}>
																				<Filter onClick={() => handleOpenFilterCard(header.filterName)} style={{ cursor: 'pointer' }} />
																			</CustomTooltip>
																			{
																				(header.enableFilter && openFilterCard && filterDropdowns === header.filterName) &&
																				<div className='table-filter-card-container d-flex flex-column align-items-start'>
																					<div className='table-filter-card-header'>
																						<span className='body-2 medium black-700'>Filters</span>
																						<span className='body-3 dark-100' onClick={() => handleResetFIlter(header.filterName)}>Reset Filter</span>
																					</div>
																					<div className='w-100 d-flex flex-column align-items-start'>
																						<div className='radio-section' >
																							{header?.filterOptions?.map((option, i) => (
																								<>
																									<div key={i} className='select-radio pointer' onClick={() => handleFilterChange(header.filterName, option)}>
																										<input
																											readOnly
																											type='radio'
																											name={header.filterName}
																											value={option}
																											checked={headerFilters[header.filterName]?.value === option.value}
																										/>
																										<span className='primary-checkbox-title ms-2 description'>{option.name}</span>
																									</div>
																								</>
																							))}
																						</div>
																					</div>
																				</div>
																			}
																		</div>
																	</>
																</div>
																:
																!header.disableSorting ?
																	<CustomTooltip customPosition={{ bottom: '55px', left: '0px' }} text={`Click to Sort`}>
																		<div className={`d-flex justify-content-start align-items-center`} style={{ width: 'max-content', cursor: 'pointer', gap: '12px' }} onClick={() => { !header.disableSorting && handleSort(header); }}>
																			<span className='body-2' style={{ color: '#808191' }}>{header?.name}</span>
																			<div className=' d-flex flex-column justify-content-center ' style={{ marginLeft: '0px' }}>
																				<ArrowUp style={{ cursor: 'pointer', stroke: sorting.column === header?.name && sorting.order === 'asc' ? '#121216' : '#808191' }} />
																				<ArrowUp style={{ cursor: 'pointer', rotate: '180deg', stroke: sorting.column === header?.name && sorting.order === 'desc' ? '#121216' : '#808191' }} />
																			</div>
																		</div>
																	</CustomTooltip>
																	:
																	(header.backendSorting && backendFilter) ?
																		<CustomTooltip customPosition={{ bottom: '55px', left: '0px' }} text={`Click to Sort`}>
																			<div className={`d-flex justify-content-start align-items-center`} style={{ width: 'max-content', cursor: 'pointer', gap: '12px' }} onClick={() => { handleBackendSort(header); }}>
																				<span className='body-2' style={{ color: '#808191' }}>{header?.name}</span>
																				<div className=' d-flex flex-column justify-content-center ' style={{ marginLeft: '0px' }}>
																					<ArrowUp style={{ cursor: 'pointer', stroke: selectedsortOption.column === header?.id && selectedsortOption.order === 'ASC' ? '#121216' : '#808191' }} />
																					<ArrowUp style={{ cursor: 'pointer', rotate: '180deg', stroke: selectedsortOption.column === header?.id && selectedsortOption.order === 'DESC' ? '#121216' : '#808191' }} />
																				</div>
																			</div>
																		</CustomTooltip>
																		:
																		<div className={`d-flex justify-content-start align-items-center`} style={{ width: 'max-content', cursor: 'pointer', gap: '12px' }} onClick={() => { !header.disableSorting && handleSort(header); }}>
																			<span className='body-2' style={{ color: '#808191' }}>{header?.name}</span>
																		</div>

															}
														</div>
													</div>
												</th>
											}
										</React.Fragment>);
								})
							}
						</tr>
					</thead>
					<tbody>
						{!loading &&
							rows?.map((row, rowIndex) => {
								const rowLength = props.showActions ? headerLength - 1 : headerLength;
								return (
									<React.Fragment key={rowIndex}>
										{props.enableCheckbox &&
											<div className='select-row-checkbox'>
												<Checkbox onChange={() => handleSelectRow(row?.id)} checked={row['selected'] ? true : false} primary />
											</div>
										}
										{
											
											<tr className='body-3 table-row' style={{ background: ((props.highlightActiveRow && activeRow === rowIndex) || row['selected']) ? '#F4F6F6' : 'white' }} onClick={() => {(assessSpecRowClick && handleRowClick(row)); setActiveRow(rowIndex); setSelectedRow(row); setIsModalOpen(true); (onSelectRow && onSelectRow(row)); (setHighlightActiveRow && setHighlightActiveRow(true));
											}} key={rowIndex}>
												{
													row && headers.map((header, entityIndex) => {
														return (
															<React.Fragment key={entityIndex}>
																{
																	((isOnWebScreen && header.screens.includes('web')) || (isOnTabletScreen && header.screens.includes('tab')) || (isOnMobileScreen && header.screens.includes('mob'))) &&
																	<td
																		className='table-col body-2 dark-100'
																		style={{ paddingLeft: '23px' }}
																		key={entityIndex}
																	>
																		{
																			((headers[entityIndex].type === 'string' && !headers[entityIndex].timeDifference) || headers[entityIndex].type === 'number') &&
																			<a
																				style={{textDecoration:'none',color:'black',width:'max-content'}}
																				href={`/dashboard/assessments/${row?.spec_id}?result=${row?.id}`}
																				onClick={(e) => {e.preventDefault();}}>
																				{header.id === 'hiringStage' ? 
																					<div className='table-email-text d-flex align-items-center' style={{height:'58px',width:'100%'}}>
																						{['Pending', 'PENDING'].includes(row[header.id]) ? 'Not Yet Evaluated' : row[header.id] }</div> : 
																					<div className='table-email-text d-flex align-items-center' style={{height:'58px',width:'100%'}}>{row[header.id]}</div>
																				}</a>
																		}
																		{
																			headers[entityIndex].type === 'component' &&
																			<>
																				<a
																					style={{textDecoration:'none',color:'black',width:'max-content'}}>
																					<div className='hover-primary-text d-flex align-items-center'  style={{height:'58px',width:'100%'}}> {row[header.id]}</div>
																				</a>
																			</>
																		}
																		{
																			headers[entityIndex].type === 'string' && headers[entityIndex].timeDifference &&
																			<a
																				style={{textDecoration:'none',color:'black',width:'max-content'}} href={`/dashboard/assessments/${row?.spec_id}?result=${row?.id}`}
																				onClick={(e) => { 
																					e.preventDefault();}}>
																				<CustomTooltip
																					position='top-end'
																					text={` ${utcFormatDate(new Date(row[header.id]))}`}
																				>
																					<div className='hover-primary-text d-flex align-items-center'  style={{height:'58px',width:'100%'}}> {timeDifference(new Date(row[header.id]))}</div>
																				</CustomTooltip>
																			</a>
																		}
																		{
																			headers[entityIndex].type === 'date' &&
																			<span>{new Date(row[header.id]).toLocaleTimeString()}</span>
																		}
																	
																	</td>
																}
															</React.Fragment>
														);
													})
												}
												{
													props.showActions &&
													<td className='py-4 px-2'></td>
												}
											</tr>
										
										}
									</React.Fragment>
								);
							})
						}
					</tbody>
				</table >
			</div>
			{loading &&
				<div className='w-100 d-flex flex-column align-items center justify-content-center' style={{ height: '190px' }}>
					<ProcessingIndicator />
				</div>
			}
			{(filteredData === null || !filteredData?.length) ?
				<div className='empty-table-text' style={{ gap: '12px' }}>
					<span className='subtitle-2 dark-100'>{props.nullTableTitle}</span>
					<span className='body-2 dark-50'>{props.nullTableDescription}</span>
				</div>
				:
				<>
					{props?.disablePagination ?
						<></>
						:
						<div>
							{(filteredRow?.length) &&
								<>
									{/* <div className='table-show-rows-select'>
									<Select readOnly
										options={[{ name: '10', value: 10 }, { name: '50', value: 50 }, { name: '100', value: 100 }]}
										onSelection={(option) => { setRowsPerPage(option.value); setCurrentPage(1); }} title={'Rows per page'}
										selected={rowsPerPage}
										placeholder={'Choose'}
										titleStyle={{ color: '#6E6B7B' }}
										innerStyle={{ width: '120px' }}
									/>
									<div className='d-flex justify-content-end' style={{ flexBasis: '50%' }}>
										<Pagination
											currentPage={currentPage}
											setCurrentPage={setCurrentPage}
											totalPages={totalPages}
											onPageChange={handlePageChange}
										/>
									</div>
								</div> */}
									{backendFilter ?
										<div className='table-pagination-container'>
											<span className='body-2 dark-50'>{`Showing ${((props.currentPage - 1) * props.itemsPerPage) + 1} - ${Math.min(props.currentPage * props.itemsPerPage, props.totalItems)} `}</span>
											<span className='body-2 dark-100'>{` of ${props.totalItems}`}</span>
											<BackArrow className='pointer' style={{ width: '20px', height: '20px' }} onClick={() => { props.currentPage < 1 ? {} : props.prevSlide(); }} />
											<ForwardArrow className='pointer' style={{ width: '20px', height: '20px' }} onClick={() => { props.currentPage === props.totalPages ? {} : props.nextSlide(); }} />
										</div>
										:
										<div className='table-pagination-container'>
											<span className='body-2 dark-50'>{`Showing ${filteredRow.length === 0 ? start : start + 1}-${Finish > filteredRow.length ? filteredRow.length : Finish}`}</span>
											<span className='body-2 dark-100'>{` of ${filteredRow.length}`}</span>
											<BackArrow className='pointer' style={{ width: '20px', height: '20px' }} onClick={() => { start < 1 ? {} : prevSlide(); }} />
											<ForwardArrow className='pointer' style={{ width: '20px', height: '20px' }} onClick={() => { Finish === filteredRow.length ? {} : nextSlide(); }} />
										</div>
									}
								</>
							}
						</div>
					}
				</>
			}
		</div>
	);
}
