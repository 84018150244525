import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './views/App';
// import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';

//bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css';

//fonts
import './assets/font/Poppins/Poppins-Regular.ttf';
//Redux
// import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from 'react-redux';
import { setStore } from './api/axios/interceptors';
// import allReducer from "./redux/reducers";
// import thunk from "redux-thunk";
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(allReducer, composeEnhancers(applyMiddleware(thunk)));

import store from './redux/store';

import { Quill } from 'react-quill';

import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.bubble.css';
import 'highlight.js/styles/darcula.css';


const Video = Quill.import('formats/video');
const Link = Quill.import('formats/link');
const icons = Quill.import('ui/icons');

import posthog from 'posthog-js';
import { getCodeBlockIcon } from './utils/utilities';

icons['code-block'] = getCodeBlockIcon();

Sentry.init({
	dsn: window._env_.REACT_APP_SENTRY_DSN,
	integrations: [new Sentry.BrowserTracing({ tracingOrigins: ['*'] })],

	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,
	environment: window._env_.NODE_ENV,
});

class CoustomVideo extends Video {
	static create(value) {
		const node = super.create(value);

		const video = document.createElement('video');
		video.setAttribute('controls', true);
		video.setAttribute('type', 'video/mp4');
		video.setAttribute('style', 'width: 400px');
		video.setAttribute('src', this.sanitize(value));
		node.appendChild(video);

		return node;
	}

	static sanitize(url) {
		return Link.sanitize(url);
	}
}
CoustomVideo.blotName = 'video';
CoustomVideo.className = 'ql-video';
CoustomVideo.tagName = 'DIV';

Quill.register('formats/video', CoustomVideo);

// Sentry.init({
// 	dsn: window._env_.SENTRY_DSN,
// 	integrations: [new Integrations.BrowserTracing()],
// 	tracesSampleRate: 1.0,
// });

setStore(store);

if (window._env_.NODE_ENV === 'production') {
	console.log = () => { };
}

// if (!window.location.host.includes('localhost')) {
posthog.init(window._env_?.REACT_APP_POSTHOG_APIKEY, {
	api_host: window._env_?.REACT_POSTHOG_PROXY,
	disable_session_recording: true,
	loaded: () => {
		posthog.startSessionRecording();
	},
	session_recording: {
		maskCapturedNetworkRequestFn: (request) => {
			// ...redact the request or response body **however makes sense for your payloads**
			request.requestBody = request.requestBody?.replace(/"password":\s*"[^"]*"/g, '"password": "redacted-password"');
			request.responseBody = request.responseBody?.replace(/"password":\s*"[^"]*"/g, '"password": "redacted-password"');

			request.requestBody = request.requestBody?.replace(/"invite_token":\s*"[^"]*"/g, '"invite_token": "redacted-token"');

			request.responseBody = request.responseBody?.replace(/"access_token":\s*"[^"]*"/g, '"access_token": "redacted-token"');

			request.requestBody = request.requestBody?.replace(/"refresh_token":\s*"[^"]*"/g, '"refresh_token": "redacted-token"');
			request.responseBody = request.responseBody?.replace(/"refresh_token":\s*"[^"]*"/g, '"refresh_token": "redacted-token"');

			request.requestBody = request.requestBody?.replace(/"googleOauthCode":\s*"[^"]*"/g, '"googleOauthCode": "redacted-code"');

			request.requestBody = request.requestBody?.replace(/"activation_token":\s*"[^"]*"/g, '"activation_token": "redacted-token"');

			return request;
		}
	}
});
// }

// ReactDOM.render(
// 	<React.StrictMode>
// 		<Provider store={store}>
// 			<App />
// 		</Provider>
// 	</React.StrictMode>,
// 	document.getElementById('root')
// );

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	// <React.StrictMode>
	<Provider store={store}>
		<App />
	</Provider>,
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

export default store;
