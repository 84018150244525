import React, { useMemo, useState, useRef } from 'react';
import Input from '../../../../components/sub-component/Input';
import TextArea from '../../../../components/sub-component/TextArea';
import TestContentWrapper from './TestContentWrapper';
import { ReactComponent as AddIcon } from '../../../../assets/icons/candidate-assessment/plus.svg';
import { ReactComponent as UploadIcon } from '../../../../assets/icons/candidate-assessment/upload.svg';
import { ReactComponent as AttachIcon } from '../../../../assets/icons/candidate-assessment/attach.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/candidate-assessment/trash.svg';
import { ReactComponent as CrossIcon } from '../../../../assets/icons/candidate-assessment/x.svg';
import { useDispatch, useSelector } from 'react-redux';
import { createResource, deleteResource, resetResourceState } from '../../../../redux/thunks/Resource';
import { useEffect } from 'react';
import ProcessingIndicator from '../../../../components/common/ProcessingIndicator';
import Timer from '../../../../components/common/Timer';
import { useFocusWithin } from '@react-aria/interactions';
import Button from '../../../../components/sub-component/Button';
import ProgressBar from '../../../../components/common/ProgressBar';

export default function TextResponsesCS(props) {

	const { question, canUploadFile } = props;
	const [answer, setAnswer] = useState('');
	const [selectedFileName, setSelectedFileName] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [error, setError] = useState();
	const firstLog = useRef(false);

	// const [uplaodedFile, setUplaodedFile] = useState({
	// 	file: null,
	// 	error: ''
	// });
	// const [showSkipQuestionModal, setShowSkipQuestionModal] = useState(false);

	const dispatch = useDispatch();
	const resource = useSelector((state) => state.resourceReducer);

	const handleFileUpload = (event) => {

		if ((event.target.files[0].size / 1024) / 1024 > 5) {
			setError('The uploaded file exceeded the max size limit (i.e 5mb)');
			return;
		}

		setError('');

		if (resource?.data?.id) {
			dispatch(deleteResource({ id: resource.data.id }));
		}

		const formData = new FormData();
		formData.append('file', event.target.files[0], event.target.files[0].name);
		setSelectedFileName(event.target.files[0].name);
		setSelectedFile(event.target.files[0]);
		// setSelectedFile({ ...selectedFile, file: { name: event.target.files[0].name } });
		dispatch(createResource({ data: formData, signal: null }));


	};

	const handleCancelUpload = () => {
		dispatch(deleteResource({ id: resource.data.id }));
		setSelectedFileName(null);
		setSelectedFile(null);
	};

	useEffect(() => {

		if (resource.data !== null) {
			dispatch(resetResourceState());
		}

		setAnswer('');

		return () => {
			if (canUploadFile && !submitted && resource.data?.id) {
				dispatch(deleteResource({ id: resource.data.id }));
			}
		};

	}, [question]);

	const onQuestionSubmit = () => {

		const data = {
			assess_id: props.assessmentId,
			response_text: answer,
			...(canUploadFile && resource.data?.id && { resource_id: resource.data.id })
		};

		// if (!data.assess_id || (!data.response_text && !data.resource_id)) {
		// 	setError('Cannot submit empty answer');
		// 	return;
		// }

		setSubmitted(true);

		if (props.onQuestionSubmit) {
			setSelectedFileName(null);
			setSelectedFile(null);
			props.onQuestionSubmit(data);
		}

		// if ((answer || answer !== '') && uplaodedFile.file) {
		// 	//hit a patch api submit question 
		// }
		// else if (answer && uplaodedFile.file === null) {
		// 	setUplaodedFile({ ...uplaodedFile, error: 'Kindly upload the file!' });
		// }
		// else {
		// 	//show confermation to skip the question
		// 	props.onQuestionSkip();

		// }
	};


	// useMemo(() => {
	// 	if (uplaodedFile.error) {
	// 		setTimeout(() => {
	// 			setUplaodedFile({ ...uplaodedFile, error: '' });
	// 		}, 3000);
	// 	}
	// }, [uplaodedFile.error]);

	const remainTime = useMemo(() => {
		if (!question) return 0;

		if (error) {
			setError(null);
		}
		const serveTime = new Date(question.serve_time);
		const duration = question.duration * 1000;

		const remainingTime = (serveTime.getTime() + duration - Date.now()) / 1000;
		return Number(remainingTime.toFixed(0));
	}, [question]);

	const [timeDuration, setTimeDuration] = useState(0);
	const [isSkipModalOpen, setIsSkipModalOpen] = useState(false);
	const { focusWithinProps } = useFocusWithin({
		onFocusWithin: e => { },
		onBlurWithin: e => { setIsSkipModalOpen(false); }
	});

	useMemo(() => {
		if (timeDuration === 0) {
			setIsSkipModalOpen(false);
		}
	}, [timeDuration]);


	return (
		<TestContentWrapper
			title={'Text Response'}
			// duration={question?.duration || 0}
			duration={remainTime}
			currentQuestion={props.currentQuestionNo}
			totalQuestions={props.totalQuestions || 0}
			// onSubmit={onQuestionSubmit}
			is_mandatory={props.is_mandatory}
			processing={props.processing}
			answer={(answer === '' && (canUploadFile ? selectedFileName === null ? true : false : true)) ? null : true}
			onSubmit={(action) => {
				if (action === 'skip') {
					const data = {
						assess_id: props?.assessmentId
					};
					if (props.onQuestionSubmit) props.onQuestionSubmit(data);
				}
				else {
					onQuestionSubmit();
				}

			}}
		>
			{
				(() => {
					if (!question) {
						return (
							<div className='align-self-center' style={{ marginTop: '100px' }}>
								<ProcessingIndicator className={'medium-indicator'} />
							</div>
						);
					}
					return (
						<div className='assessment-test-container flex-column' style={{gap: '24px'}}>
							<div className='w-100 d-flex align-items-center justify-content-between'>
								<span className='subtitle-2 dark-100 disable-text-selection text-start'>{question?.text}</span>
								<div style={{ width: '85px' }}>
									<Timer 
									duration={remainTime}
									getUpdatedTime={(duration) => {
										if (!firstLog.current) {
											console.log("Initial Time Duration TR:", duration);
											firstLog.current = true; 
											}if (duration === 0) {
												console.log("Timer reached 0!");
											}setTimeDuration(duration); 
											}}/>;
								</div>
							</div>
							<div className='w-100 d-flex flex-column' 
								style={{ 
									gap: '24px',
									opacity: (timeDuration <= 0) ? '0.3' : '1', 
									pointerEvents: (timeDuration <= 0) ? 'none' : 'auto' 
								}} 
								onKeyDown={(e) => timeDuration <= 0 && e.preventDefault()}
							>
								<TextArea
									inputStyle={{ minHeight: '270px', padding: '25px' }}
									value={answer}
									onChange={(e) => { setAnswer(e.target.value); }}
									placeholder='Write your answer here...'
								/>
								{canUploadFile && 
									<>
										<div className='text-upload-doc-container'>
											<div className='d-flex flex-column align-items-center pointer' style={{ position: 'relative', width: '260px', height: '90px', gap: '12px' }}>
												<UploadIcon />
												<span className='subtitle-2 dark-100'>Click to upload or drag and drop</span>
												<span className='body-2 dark-50'>pdf, csv, txt, or doc (max size 5mb)</span>
												<input
													className='pointer'
													style={{ position: 'absolute', left: 0, right: 0, width: '100%', height: '100%', opacity: 0 }}
													type='file'
													disabled={resource.processing}
													accept='.pdf, .csv, .txt, .doc, .docx'
													onChange={!question?.duration <= 0 ? handleFileUpload : null}
												/>
											</div>
										</div>
										{resource?.upload_percentage > 0 ?
											<div className='uploaded-doc-container'>
												<AttachIcon />
												<div className='d-flex flex-column align-items-start' style={{width: 'calc(100% - 36px)', gap: '8px'}}>
													<div className='w-100 d-flex flex-row align-items-center justify-content-between'>
														<div className='w-100 d-flex flex-column align-items-start'>
															<span className='body-2 dark-100'>{selectedFileName}</span>
															<span className='body-2 dark-100'>{(selectedFile?.size / 1024).toFixed(1)} kb</span>
														</div>
														{resource?.upload_percentage < 100 ? 
															<CrossIcon cursor='pointer' onClick={handleCancelUpload} />
															:
															<DeleteIcon cursor='pointer' onClick={handleCancelUpload} />
														}
													</div>
													<ProgressBar 
														varrient={'progress-with-upload-doc'}
														value={resource?.upload_percentage}
													/>
												</div>
											</div>
											: 
											error &&
											<div className='uploaded-doc-container align-items-center'>
												<AttachIcon />
												<span className='body-2 danger-text'>{error}</span>
											</div>
										}
									</>
								}
							</div>
							<div className='w-100 d-flex justify-content-end align-items-end'>
								<div tabIndex={-1} {...focusWithinProps}>
									{isSkipModalOpen ?
										<Button
											processing={props.processing}
											btn='ragular-btn' 
											varrient={'primary'}
											title={'Skip Question?'}
											style={{ width: 'max-content', height: '40px' }}
											onClick={() => {
												const data = {
													assess_id: props?.assessmentId
												};
												if (props.onQuestionSubmit) props.onQuestionSubmit(data);

												setIsSkipModalOpen(false);
											}} 
										/>
										:
										<Button
											processing={props.processing}
											disabled={props.is_mandatory
												&& (timeDuration === null || timeDuration > 0)
												&& answer === ''
												&& (canUploadFile ? (selectedFileName === null ? true : false) : true)
											}
											btn='ragular-btn' 
											varrient={'primary'}
											title={props.currentQuestionNo === props.totalQuestions || 0 ? 'Submit' : 'Next'}
											style={{ width: '120px', height: '40px' }}
											onClick={() => {
												if ((answer === '' && (canUploadFile ? (selectedFileName === null ? true : false) : true)) && (timeDuration === null || timeDuration > 0)) {
													setIsSkipModalOpen(true);
												}
												else if (!isSkipModalOpen) {
													onQuestionSubmit();
													setIsSkipModalOpen(false);
												}
											}} 
										/>
									}
								</div>
							</div>
						</div>
					);
				})()
			}
		</TestContentWrapper>
	);
}
