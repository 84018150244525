import React, { useEffect, useRef, useState } from 'react';
import { secondsToDhms, secondsToDhmsFormat } from '../../utils/utilities';


export default function Timer(props) {

	const { duration } = props;

	const [timeDuration, setTimeDuration] = useState(duration > 0 ? duration : 0);
	const timeDurationRef = useRef(duration > 0 ? duration : 0);
	const timerRef = useRef();

	const updateTime = () => {
		// setTimeDuration(timeDuration - 1);
		if (timeDurationRef.current <= 0) return;

		timeDurationRef.current--;
		setTimeDuration(timeDurationRef.current);
		props?.getUpdatedTime(timeDurationRef.current);
		timerRef.current = setTimeout(() => updateTime(), 1000);
	};

	useEffect(() => {
		if (!duration) return;

		if (timerRef.current) {
			clearTimeout(timerRef.current);
			timerRef.current = null;
		}

		// setTimeDuration(duration);
		timeDurationRef.current = duration;
		updateTime();

		return (() => {
			timeDurationRef.current = null;
		});
	}, [duration]);

	return (
		<>
			{
				duration !== null &&
			<span  className={`${props.isCaseStudy ? 'subtitle-2' : 'subtitle-2'} ${timeDuration <= 30 ? 'danger-text' : 'dark-100'}`} style={{ ...props.style }}>
				{timeDuration > 0 
				? (props.isCaseStudy 
            ? secondsToDhmsFormat(timeDuration, true) 
            : secondsToDhms(timeDuration)) : "0 sec"}
			</span>
			}
		</>
	);
}
