import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserProfile,
  getTanentProfile,
} from "../../redux/thunks/UserProfile";
import CryptoJS from "crypto-js";
import hexDigest from "crypto-js/enc-hex";
import { GenerateHash } from "../../redux/thunks/CustomerSupport";

export const initializeChatwoot = async (token, baseUrl) => {
  if (window.chatwootSDK) {
    window.chatwootSDK.run({
      websiteToken: token,
      baseUrl: baseUrl,
    });
  }
};

export const setChatwootUser = async (
  auth,
  user_role,
  userProfileData,
  dispatch,
  name
) => {
  if (window.$chatwoot && auth?.data?.access_token && userProfileData) {
    const result = await dispatch(
      GenerateHash({ is_candidate: user_role === "candidate" })
    );
    const hash = result.payload?.[1]?.hash;

    if (hash) {
      window.$chatwoot.setUser(auth?.data?.user_id.toString(), {
        email: auth?.data?.email,
        name: name,
        identifier_hash: hash,
      });
    } else {
      console.error("Failed to generate hash for Chatwoot user.");
    }
  } else {
    console.error("Chatwoot is not ready or missing user data.");
  }
};

export default function ChatwootComponent({ children }) {
  const baseUrl = window._env_.CHATWOOT_BASE_URL;
  const adminToken = window._env_.CHATWOOT_WEB_ACCESS_TOKEN;
  const candidateToken = window._env_.CANDIDATE_CHATWOOT_WEB_ACCESS_TOKEN;
  const { userProfileData } = useSelector((state) => state.userProfileReducer);
  const auth = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const USER_NAME =
    `${userProfileData?.first_name} ${userProfileData?.last_name}`.trim();
  const user_role = localStorage.getItem("user_role");

  const loadChatwootScript = () => {
    if (!document.getElementById("chatwoot-script")) {
      const script = document.createElement("script");
      script.id = "chatwoot-script";
      script.src = `${baseUrl}/packs/js/sdk.js`;
      script.async = true;
      script.onload = () => {
        console.log("Chatwoot SDK script loaded");
      };
      document.body.appendChild(script);
    }
  };

  const initializeWidget = async () => {
    const isCandidate = user_role === "candidate";
    const token = isCandidate ? candidateToken : adminToken;
    await initializeChatwoot(token, baseUrl);
    if (auth.data?.access_token && userProfileData) {
      await setChatwootUser(
        auth,
        user_role,
        userProfileData,
        dispatch,
        USER_NAME
      );
    } else {
      console.warn("No user data available for Chatwoot.");
    }
  };

  useEffect(() => {
    if (auth.data?.access_token) {
      dispatch(getUserProfile({}));
      if (auth?.data?.role?.id !== "candidate") {
        dispatch(getTanentProfile({ id: "", isCallForCurrAssessment: false }));
      }
    }
  }, [auth.data?.access_token]);

  useEffect(() => {
    const setupChatwoot = async () => {
      await loadChatwootScript(); // Ensure script is loaded
      await initializeWidget(); // Initialize widget and set user
    };

    setupChatwoot();
  }, [
    baseUrl,
    adminToken,
    candidateToken,
    user_role,
    auth?.data?.access_token,
    auth?.data?.role?.id,
    userProfileData,
  ]);

  return <div>{children}</div>;
}
