import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams, useLocation } from 'react-router-dom';
// import { createAssessment } from '../redux/thunks/Assessment';
import { refreshToken, backgroundRefreshToken } from '../redux/thunks/Auth';
import { getNotification } from '../redux/thunks/Notification';
// import { refreshToken } from "./redux/reducers/AppReducer";
import { AppState, AssessmentStatus, PathType, RequestStatus, fullBlock } from '../utils/Constants';
import { MainRoutes, PathName } from '../utils/routes';
// import useAppHistory from '../utils/useAppHistory';
import { googleSignup, googleLogin } from './../redux/thunks/Auth';
import { getAssessment } from '../redux/thunks/Assessment';
import { getUserProfile } from '../redux/thunks/UserProfile';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import { resetNotificationData } from '../redux/slices/NotificationSlice';

export default function RoutesWrapper({ children }) {
	// const appHistory = useAppHistory();

	// const [invited, setInvited] = useState(false);

	const params = useParams();
	const search = useLocation().search;

	const inviteId = new URLSearchParams(search).get('invite_id');
	const oAuthState = new URLSearchParams(search).get('state');
	const oAuthClientId = new URLSearchParams(search).get('client_id');
	const oAuthRedirectUri = new URLSearchParams(search).get('redirect_uri');
	const oAuthResponseType = new URLSearchParams(search).get('response_type');
	const googleOauthCode = new URLSearchParams(search).get('code');
	const googleOauthStateEncoded = new URLSearchParams(search).get('state');
	const test_type = new URLSearchParams(search).get('test_type');
	const test_id = new URLSearchParams(search).get('test_id');
	const assess_id = new URLSearchParams(search).get('assess_id');
	const public_assess_id = new URLSearchParams(search).get('public_assess_id');
	const error = new URLSearchParams(search).get('error');


	/**@type {AuthState} */
	const auth = useSelector((state) => state.authReducer);

	// const access_token = useSelector((state) => state.authReducer?.data?.access_token);
	// console.log(access_token,'assesaccess_tokensmenttest');

	const dispatch = useDispatch();

	const history = useHistory();



	useEffect(() => {
		const urlPattern = /^(?:http|https):\/\/[^\\/]+\/(assessment_invitation|signup)\/\?invite_token=([^&]+)&assess_id=([^&]+)$/;
		const match = window.location.href.match(urlPattern);
		if (match) {
			const matchedPath = match[1];
			const inviteToken = match[2];
			const assessId = match[3];

			if (matchedPath === 'assessment_invitation' || matchedPath === 'signup') {
				const redirectUrl = `${PathName.CANDIDATE_ACTIVATION}?invite_token=${inviteToken}&assess_id=${assessId}`;
				window.location.replace(redirectUrl);
			}
		}
	}, []);
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const hasSignup = urlParams.has('signup');
		const hasInviteToken = urlParams.has('invite_token');
		const hasAssessId = urlParams.has('assess_id');
		if (hasSignup && hasInviteToken && !hasAssessId) {
			window.location.replace(PathName.ASSESSMENTS);
		}
	}, []);



	useEffect(() => {
		if (test_type && test_id) {
			localStorage.setItem('test_type', test_type);
			localStorage.setItem('test_id', test_id);
		}
	}, []);

	useEffect(() => {
		if (oAuthState && oAuthClientId && oAuthRedirectUri && oAuthResponseType) {
			sessionStorage.setItem('oAuthState', oAuthState);
			sessionStorage.setItem('oAuthClientId', oAuthClientId);
			sessionStorage.setItem('oAuthRedirectUri', oAuthRedirectUri);
			sessionStorage.setItem('oAuthResponseType', oAuthResponseType);
		}
	}, []);

	useEffect(() => {
		if (assess_id) {
			localStorage.setItem('assess_id', assess_id);
		}
	}, []);

	useEffect(() => {
		if (error === 'access_denied') {
			history.push(`/${JSON.parse(decodeURIComponent(googleOauthStateEncoded))?.authProcess === 'login' ? 'login' : JSON.parse(decodeURIComponent(googleOauthStateEncoded))?.authProcess === 'signup' && 'signup'}`);
		}
	}, [error]);

	useEffect(() => {
		dispatch(refreshToken());

		const interval = setInterval(() => {
			dispatch(backgroundRefreshToken());
		}, 30 * 60 * 1000);
		return () => {
			clearInterval(interval);
		};
	}, []);
	const [page, setPage] = useState(1);
	const [limit] = useState(11);

	useEffect(() => {
		if (page === 1) {
			dispatch(resetNotificationData());
		}
		const fetchNotifications = async () => {
			try {
				const result = await dispatch(getNotification({ page, limit }));
			} catch (error) {
				console.error('Error fetching notifications:', error);
			}
		};
		fetchNotifications();
		const pollingInterval = setInterval(() => {
			fetchNotifications();
		}, 60000); 
		return () => clearInterval(pollingInterval);
  
	}, [page, dispatch]);

		// Public link
useEffect(() => {
    if ((getCurrentPathType() === PathType.PROTECTED || getCurrentPathType() === PathType.UNPROTECTED) && inviteId) {
        history.push(`${PathName.EMAIL_INVITE}${search}`);
    }
}, [inviteId, search,history]);



	const handleGoogleAuth = async function () {

		if (googleOauthCode && !auth.data?.access_token) {
			const googleOauthState = JSON.parse(decodeURIComponent(googleOauthStateEncoded));

			// Handle Signup Process
			if (googleOauthState.authProcess == 'signup') {

				// Handle User Signup
				if (googleOauthState.invite_id || googleOauthState.invite_token) {
					const dispatched = await dispatch(googleSignup({
						data: {
							googleOauthCode,
							invite_id: googleOauthState.invite_id,
							invite_token: googleOauthState.invite_token
						}
					}));

					if (dispatched.meta.requestStatus === RequestStatus.REJECTED) {
						// Handle Error Response
						const qs = new URLSearchParams(googleOauthState);
						qs.delete('authProcess');
						qs.append('failure', `${dispatched.payload}`);

						history.push(`${PathName.SIGNUP}?${qs.toString()}`);
					} else {
						const qs = new URLSearchParams(googleOauthState);
						qs.delete('authProcess');
						history.push(`${PathName.MAIN}?${qs.toString()}`);
					}

				} // Handle Tenant Register
				else {
					const dispatched = await dispatch(googleSignup({
						data: {
							googleOauthCode
						}
					}));

					if (dispatched.meta.requestStatus === RequestStatus.REJECTED) {
						// handle error response
						const qs = new URLSearchParams(googleOauthState);
						qs.delete('authProcess');
						qs.append('failure', `${dispatched.payload}`);

						history.push(`${PathName.SIGNUP}?${qs.toString()}`);
					} else {
						const qs = new URLSearchParams(googleOauthState);
						qs.delete('authProcess');
						history.push(`${PathName.COMPANY_INFO}?${qs.toString()}`);
					}
				}

			} // Handle Login
			else if (googleOauthState.authProcess == 'login') {

				const dispatched = await dispatch(googleLogin({
					data: {
						googleOauthCode,
						remember_me: true
					}
				}));

				if (dispatched.meta.requestStatus === RequestStatus.REJECTED) {
					// handle error response
					const qs = new URLSearchParams(googleOauthState);
					qs.delete('authProcess');
					qs.append('failure', `${dispatched.payload}`);

					history.push(`${PathName.LOGIN}?${qs.toString()}`);
				} else {
					// googleOauthState holds additional params such as invite_id etc
					const qs = new URLSearchParams(googleOauthState);
					qs.delete('authProcess');
					history.push(`${PathName.MAIN}?${qs.toString()}`);
				}

			}
		}
	};

	useEffect(() => {
		handleGoogleAuth();
	}, []);

	// console.log({ inviteId });

	// if (inviteId && !getCookie('invite_id')) {
	// 	setCookie('invite_id', inviteId, 1);
	// }

	// useEffect(() => {

	// 	// console.log(history.location.pathname.split('/'));

	// 	if (auth.app_state === AppState.LOADING) {
	// 		return;
	// 	}

	// 	// if (!MainRoutes[history.location.pathname.split('/')[1]]) {
	// 	// 	history.push('/error');
	// 	// 	return;
	// 	// }

	// 	// console.log(MainRoutes[history.location.pathname.split('/')[1]]);
	// 	const currentPathType = MainRoutes[history.location.pathname.split('/')[1]]?.path_type || MainRoutes[''].path_type;
	// 	console.log({ currentPathType });
	// 	// console.log({ auth });
	// 	// if (MainRoutes[history.location.pathname.split('/')[1]].path_type === PathType.ACTIVATION) {
	// 	// 	if (auth.data.is_active) {
	// 	// 		history.push('/subscription');
	// 	// 	}
	// 	// 	if (!history.location.pathname.split('/')[2] && !auth.data.access_token) {
	// 	// 		history.push('/login');
	// 	// 	}
	// 	// }

	// 	if (currentPathType === PathType.ACTIVATION) {
	// 		if (auth.data.is_active) {
	// 			history.push('/');
	// 		}

	// 		return;
	// 	}

	// 	if (currentPathType === PathType.PROTECTED && !auth.data.access_token) {

	// 		history.push({
	// 			pathname: PathName.LOGIN,
	// 			...(inviteId && { search: `?invite_id=${inviteId}` })
	// 		});
	// 		return;
	// 	}

	// 	if ((currentPathType === PathType.AUTHENTICATION || currentPathType === PathType.PROTECTED) && auth.data.access_token) {

	// 		if (!auth.data.is_active) {
	// 			history.push(PathName.ACTIVATION_PENDING);
	// 			return;
	// 		}

	// 		if (inviteId) {
	// 			if (!invited) {
	// 				dispatch(createAssessment({ invite_id: inviteId }));
	// 				setInvited(true);
	// 			}
	// 		}

	// 		// if (getCookie('invite_id')) {
	// 		// 	console.log('Dispatching create assessment');
	// 		// }

	// 		else if (auth.data.tenant_status === null) {
	// 			history.push(PathName.SUBSCRIPTION);
	// 			return;
	// 		}

	// 		console.log('check --', history.location.pathname.split('/')[1]);

	// 		if (`/${history.location.pathname.split('/')[1]}` !== PathName.MAIN) {
	// 			history.push(PathName.MAIN);
	// 		}
	// 		// if (auth.data.roles[0].name === 'Candidate') {

	// 		// 	if (`/${history.location.pathname.split('/')[1]}` !== PathName.CANDIDATE_DASHBOARD) {
	// 		// 		history.push(PathName.CANDIDATE_DASHBOARD);
	// 		// 	}

	// 		// } else {

	// 		// 	if (`/${history.location.pathname.split('/')[1]}` !== PathName.DASHBOARD) {
	// 		// 		history.push(PathName.DASHBOARD);
	// 		// 	}
	// 		// }

	// 	}

	// 	// if(history.location.pathname[history.location.pathname.length -1] !== "/") {
	// 	// 	history.push(`${history.location.pathname}/`);
	// 	// }

	// }, [auth]);
	// useEffect(() => {

	// 	if (auth.app_state === AppState.LOADING) {
	// 		return;
	// 	}

	// 	const currentPathType = MainRoutes[history.location.pathname.split('/')[1]]?.path_type || MainRoutes[''].path_type;
	// 	console.log({ currentPathType });

	// 	if (currentPathType === PathType.ACTIVATION) {
	// 		if (auth.data.is_active) {
	// 			history.push('/');
	// 		}

	// 		return;
	// 	}

	// 	if (currentPathType === PathType.PROTECTED && !auth.data.access_token) {

	// 		history.push({
	// 			pathname: PathName.LOGIN,
	// 			...(inviteId && { search: `?invite_id=${inviteId}` })
	// 		});
	// 		return;
	// 	}

	// 	if ((currentPathType === PathType.AUTHENTICATION || currentPathType === PathType.PROTECTED) && auth.data.access_token) {

	// 		if (!auth.data.is_active) {
	// 			history.push(PathName.ACTIVATION_PENDING);
	// 			return;
	// 		}

	// 		if (inviteId) {
	// 			if (!invited) {
	// 				dispatch(createAssessment({ invite_id: inviteId }));
	// 				setInvited(true);
	// 			}
	// 		}

	// 		else if (auth.data.tenant_status === null) {
	// 			history.push(PathName.SUBSCRIPTION);
	// 			return;
	// 		}

	// 		console.log('check --', history.location.pathname.split('/')[1]);

	// 		if (`/${history.location.pathname.split('/')[1]}` !== PathName.MAIN) {
	// 			history.push(PathName.MAIN);
	// 		}

	// 	}

	// }, [auth]);

	const getCurrentPathType = () => {
		return (
			MainRoutes[history.location.pathname.split('/')[1]]?.path_type ||
			MainRoutes[''].path_type
		);
	};

	const handleStateParam = (state) => {
		try {
			return JSON.parse(googleOauthStateEncoded)?.authProcess;
		} catch {
			return false;
		}
	};


	if (auth.app_state !== AppState.READY || handleStateParam(googleOauthStateEncoded)) {
		return (
			<div
				style={{
					position: 'fixed',
					top: '0',
					bottom: '0',
					left: '0',
					right: '0',
					height: '40px',
					width: '40px',
					margin: 'auto',
				}}
			>
				<div
					className='processing-indicator primary'
					style={{ width: '40px', height: '40px' }}
				/>
			</div>
		);
	}

	if (getCurrentPathType() === PathType.ACTIVATION) {
		if (auth.data.is_active) {
			return <Redirect to={`${PathName.MAIN}${search}`} />;
		}
	}
	if (getCurrentPathType() === PathType.PROTECTED && !auth.data?.access_token) {
		return <Redirect to={`${PathName.LOGIN}${search}`} />;
	}



	if (getCurrentPathType() === PathType.PROTECTED && params?.id) {
		return <Redirect to={`${PathName.PUBLIC_ASSESSMENT_RESULT}${search}`} />;
	}
	// if (getCurrentPathType() === PathType.PROTECTED) {
	// 	return (
	// 		<Route path="*">
	// 			{auth?.data?.role?.id === 'owner' ?
	// 				<Redirect to="/dashboard/assessments/active" />
	// 				:
	// 				<></>
	// 			}
	// 			{/* <Redirect to={PathName.MAIN} /> */}
	// 			{auth?.data?.role?.id === 'candidate' ?
	// 				<Redirect to="/dashboard/assessments" />
	// 				:
	// 				<></>
	// 			}


	// 		</Route>
	// 	);
	// }


	// if (inviteId) {
	// return <Redirect to={`${PathName.PROCESS_ASSESSMENT_INVITE}${search}`}/>;
	// if (!invited) {
	// 	dispatch(createAssessment({ invite_id: inviteId }));
	// 	setInvited(true);
	// }
	// }

	if (
		getCurrentPathType() === PathType.AUTHENTICATION &&
		auth.data?.access_token
	) {
		return <Redirect to={`${PathName.MAIN}${search}`} />;
	}

	if (
		(getCurrentPathType() === PathType.AUTHENTICATION ||
			getCurrentPathType() === PathType.PROTECTED) &&
		auth.data?.access_token
	) {
		if (
			!auth.data.is_active &&
			MainRoutes[history.location.pathname.split('/')[1]]?.pathname !==
			PathName.ACTIVATION_PENDING
		) {
			return <Redirect to={`${PathName.ACTIVATION_PENDING}${search}`} />;
		}

		// if (
		// 	inviteId &&
		// 	MainRoutes[history.location.pathname.split('/')[1]]?.pathname !==
		// 	PathName.PROCESS_ASSESSMENT_INVITE
		// ) {
		// 	return <Redirect to={`${PathName.PROCESS_ASSESSMENT_INVITE}${search}`} />;
		// }

		if (
			oAuthClientId &&
			oAuthRedirectUri &&
			MainRoutes[history.location.pathname.split('/')[1]]?.pathname !==
			PathName.OAUTH
		) {
			return <Redirect to={`${PathName.OAUTH}${search}`} />;
		}

		if (
			MainRoutes[history.location.pathname.split('/')[1]]?.pathname !==
			PathName.PLAN_PAGE &&
			auth.data.tenant_status === 'pre_onboard'
		) {
			return <Redirect to={PathName.PLAN_PAGE} />;
		}

		if (
			MainRoutes[history.location.pathname.split('/')[1]]?.pathname ===
			PathName.PLAN_PAGE &&
			auth.data.tenant_status !== 'pre_onboard'
		) {
			return <Redirect to={PathName.MAIN} />;
		}

		if (history.location.pathname === '/' && auth.data?.access_token) {
			return <Redirect to={PathName.MAIN} />;
		}

		if (fullBlock.includes(auth.data?.tenant_status)) {
			if (!history.location.pathname.includes('billing') && auth.data.role.name === 'Owner') {
				return <Redirect to={PathName.SETTINGS + PathName.BILLING_SETTINGS} />;
			} else if (!history.location.pathname.includes('profile-settings') && (auth.data.role.name === 'Admin' || auth.data.role.name === 'Member')) {
				return <Redirect to={PathName.SETTINGS + PathName.USER_PROFILE_SETTINGS} />;
			}
		}

		if (
			auth.data.role.name === 'Member' &&
			history.location.pathname.includes(PathName.SETTINGS) &&
			!history.location.pathname.includes(PathName.USER_PROFILE_SETTINGS)
		) {
			return <Redirect to={`${PathName.ASSESSMENTS}${search}`} />;
		}

		if (history.location.pathname.includes(PathName.ASSESSMENTS)) {
			const curr_path = history.location.pathname;
			if (
				!curr_path.includes(PathName.ACTIVE_ASSESSMENTS) &&
				!curr_path.includes(PathName.PENDING_ASSESSMENTS) &&
				!curr_path.includes(PathName.ARCHIVED_ASSESSMENTS) &&
				!curr_path.includes('/view') &&
				!curr_path.includes('/publish') &&
				curr_path !== PathName.ASSESSMENTS
			) {
				localStorage.setItem('showAssessmentProgressCard', false);
				localStorage.setItem('showPauseAssessmentCard', false);
			} else {
				// localStorage.setItem('showAssessmentProgressCard', undefined);
				// localStorage.setItem('showPauseAssessmentCard', undefined);
				localStorage.removeItem('showAssessmentProgressCard');
				localStorage.removeItem('showPauseAssessmentCard');
			}
		} else {
			localStorage.removeItem('showAssessmentProgressCard');
			localStorage.removeItem('showPauseAssessmentCard');
		}

		// if (MainRoutes[history.location.pathname.split('/')[1]]?.pathname !== PathName.MAIN) {

		// }
	}

	// if (
	// 	auth.app_state === AppState.READY &&
	// 	MainRoutes[history.location.pathname.split('/')[1]]?.path_type === PathType.PROTECTED &&
	// 	!auth.data.access_token
	// ) {

	// 	history.push({
	// 		pathname: PathName.LOGIN,
	// 		...(inviteId && { search: `?invite_id=${inviteId}` })
	// 	});

	// 	// appHistory.push(PathName.LOGIN);
	// 	return <></>;
	// }

	return (
		<div>
			{auth.app_state === AppState.LOADING ? (
				<div
					style={{
						position: 'fixed',
						top: '0',
						bottom: '0',
						left: '0',
						right: '0',
						height: '40px',
						width: '40px',
						margin: 'auto',
					}}
				>
					<div
						className='processing-indicator primary'
						style={{ width: '40px', height: '40px' }}
					/>
				</div>
			) : (
				<>
					<>{children}</>
				</>
			)}
		</div>
	);
}
