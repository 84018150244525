export const PersonalityType = {
	INTJ: {
		pt_alias: 'Architect',
		description:
			'INTJ stands for Introverted, Intuitive, Thinking, and Judging. Individuals with the INTJ personality type are often described as strategic, logical, and independent thinkers. They possess a strong drive for knowledge and understanding, relying on their analytical abilities to dissect complex problems and develop innovative solutions. INTJs are future-oriented and visionary, often setting ambitious goals and working towards their realization with determination. While they may appear reserved and private, they value competence and efficiency. They can be critical and direct in their communication, sometimes struggling with emotional expression and social interactions. Overall, INTJs are known for their intellectual prowess, strategic thinking, and independent nature.',
		strength_info: {
			strengths: [
				{
					strength: 'Analytical and Strategic Thinking',
					description:
						'INTJs possess exceptional analytical skills and a strategic mindset. They have a natural ability to dissect complex problems, identify patterns, and develop innovative solutions. Their sharp intellect allows them to think critically and see the big picture, enabling them to devise effective plans of action.',
				},
				{
					strength: 'Independent and Self-Reliant',
					description:
						'INTJs are highly independent individuals who are confident in their own abilities. They prefer to rely on themselves rather than depending on others. This self-reliance allows them to take on challenging tasks and make decisions with conviction, often pursuing their goals with determination and perseverance.',
				},
				{
					strength: 'Visionary and Future-Oriented',
					description:
						'INTJs are known for their forward-thinking and visionary nature. They possess the ability to envision future possibilities and anticipate long-term outcomes. This foresight helps them to set ambitious goals, create strategic plans, and work towards a future they can envision. Their focus on the future enables them to navigate complex situations with a clear sense of purpose and direction.',
				},
			],
			disclaimer:
				"It's important to note that while these strengths are commonly associated with the INTJ personality type, individuals can vary in their strengths and weaknesses regardless of their type.",
		},
		weakness_info: {
			weaknesses: [
				{
					weakness: 'Difficulty with Emotional Expression',
					description:
						' INTJs tend to prioritize logic and rationality over emotions. As a result, they may struggle with expressing their own emotions or understanding the emotions of others. This can make it challenging for them to connect with people on an emotional level or offer emotional support in certain situations.',
				},
				{
					weakness: 'Perfectionism and Overly Critical',
					description:
						'INTJs have high standards for themselves and others, often striving for perfection in their work and endeavors. This can lead to a tendency to be overly critical, both of themselves and those around them. They may have difficulty accepting imperfections and may become frustrated when others do not meet their expectations.',
				},
				{
					weakness: 'Impatience with Inefficiency',
					description:
						'INTJs have a strong desire for efficiency and effectiveness. They value getting things done quickly and may become impatient with processes or individuals they perceive as inefficient or incompetent. This impatience can sometimes lead to a lack of tolerance for differing work styles or a tendency to dismiss ideas or approaches that do not align with their own.',
				},
			],
			disclaimer:
				"It's important to remember that these weaknesses are not universal to all INTJs and that individuals may exhibit different strengths and weaknesses within their personality type. Additionally, awareness of these weaknesses can help INTJs to navigate and manage them effectively.",
		},
		career_path_info: {
			career_paths: [
				{
					career_path: 'Strategic Planning/Management',
					description:
						"INTJs' analytical and strategic thinking abilities make them well-suited for roles in strategic planning, where they can develop long-term organizational goals, analyze market trends, and create efficient strategies for growth.",
				},
				{
					career_path: 'Science/Research',
					description:
						'With their natural curiosity and love for knowledge, INTJs excel in scientific and research-oriented fields. They can thrive as scientists, researchers, or analysts, where their logical and investigative skills can contribute to groundbreaking discoveries.',
				},
				{
					career_path: 'Technology/Engineering',
					description:
						"INTJs' penchant for problem-solving and their logical approach make them a good fit for careers in technology and engineering. They can excel in areas such as software development, systems analysis, or engineering, where their ability to envision and create complex systems comes into play.",
				},
				{
					career_path: 'Entrepreneurship',
					description:
						"INTJs' independent nature and visionary mindset make them well-suited for entrepreneurship. Their ability to identify gaps in the market, develop innovative solutions, and make strategic decisions can lead to successful ventures.",
				},
				{
					career_path: 'Legal/Intellectual Property',
					description:
						"INTJs' attention to detail and analytical thinking can be valuable in legal and intellectual property fields. They can excel as lawyers, legal researchers, or patent examiners, where their ability to analyze complex information and navigate intricate legal frameworks is highly valuable.",
				},
			],
			disclaimer:
				"It's important to note that while these career paths align with many INTJs' strengths, individual preferences, interests, and skills can vary. It's essential for INTJs to explore their own passions and consider how their strengths can be applied to different fields to find a fulfilling career path.",
		},
	},
	INTP: {
		pt_alias: 'Thinker',
		description:
			'INTP stands for Introverted, Intuitive, Thinking, and Perceiving. Individuals with the INTP personality type are often described as logical, analytical, and independent thinkers. They have a deep curiosity and desire for knowledge, enjoying exploring complex ideas and theories. INTPs are skilled at analyzing information and finding innovative solutions to problems. They are highly introspective and value their personal autonomy. INTPs may appear reserved and prefer solitary activities that allow them to engage their intellect. They can be highly creative and have a penchant for abstract thinking. While they may struggle with decision-making and structure, they excel in fields that allow for intellectual exploration and independent problem-solving.',
		strength_info: {
			strengths: [
				{
					strength: 'Analytical and Critical Thinking',
					description:
						'INTPs have a deep curiosity and thirst for knowledge. They enjoy exploring a wide range of subjects and delving into complex ideas and theories. Their open-mindedness and desire to understand the world around them drive them to continuously seek new information and expand their intellectual horizons.',
				},
				{
					strength: 'Intellectual Curiosity and Love for Learning',
					description:
						'INTPs have a deep curiosity and thirst for knowledge. They enjoy exploring a wide range of subjects and delving into complex ideas and theories. Their open-mindedness and desire to understand the world around them drive them to continuously seek new information and expand their intellectual horizons.',
				},
				{
					strength: 'Creative and Original Thinking',
					description:
						'INTPs possess a unique perspective and a penchant for creative thinking. They can generate novel ideas, connect seemingly unrelated concepts, and approach problems from unconventional angles. Their ability to think outside the box enables them to come up with innovative solutions and contribute fresh insights to various domains.',
				},
			],
			disclaimer:
				"It's important to note that while these strengths are commonly associated with the INTP personality type, individuals can vary in their strengths and weaknesses regardless of their type.",
		},
		weakness_info: {
			weaknesses: [
				{
					weakness: 'Decision Paralysis',
					description:
						'INTPs can become overwhelmed when faced with making decisions, especially when there are multiple possibilities and variables to consider. Their desire to explore all angles and gather more information can lead to a tendency to procrastinate or delay decision-making, which can hinder progress or cause missed opportunities.',
				},
				{
					weakness: 'Difficulty with Emotional Expression',
					description:
						'INTPs tend to prioritize logical and rational thinking over emotional considerations. They may struggle with expressing their own emotions or understanding the emotions of others. This can make it challenging for them to navigate interpersonal relationships and offer emotional support in certain situations.',
				},
				{
					weakness: 'Disorganization and Lack of Structure',
					description:
						'INTPs often prefer flexibility and spontaneity over strict routines or structure. While this can foster creativity, it can also lead to difficulties in organizing their environment or managing time effectively. They may struggle with maintaining focus and completing tasks that require consistency or attention to detail.',
				},
			],
			disclaimer:
				"It's important to remember that these weaknesses are not universal to all INTPs, and individuals may exhibit different strengths and weaknesses within their personality type. Awareness of these weaknesses can help INTPs develop strategies to manage them and find ways to leverage their strengths effectively.",
		},
		career_path_info: {
			career_paths: [
				{
					career_path: 'Scientific Research',
					description:
						"INTPs' analytical thinking, curiosity, and love for learning make them well-suited for careers in scientific research. They can excel in fields such as physics, chemistry, biology, or psychology, where their ability to analyze complex data and develop innovative theories is highly valued.",
				},
				{
					career_path: 'Information Technology',
					description:
						"INTPs' logical and creative thinking, as well as their affinity for problem-solving, make them a good fit for careers in information technology. They can thrive as software developers, data analysts, or system architects, where their ability to analyze complex systems and find innovative solutions is in demand.",
				},
				{
					career_path: 'Engineering',
					description:
						"INTPs' analytical mindset, problem-solving skills, and passion for understanding how things work make them suitable for engineering careers. They can excel in fields such as mechanical engineering, electrical engineering, or computer engineering, where their ability to design and optimize complex systems is valued.",
				},
				{
					career_path: 'Philosophy/Philosophical Research',
					description:
						"INTPs' love for intellectual exploration and abstract thinking can lead them to careers in philosophy or philosophical research. They can thrive in academia, contributing to the exploration of complex ideas, theories, and ethical frameworks.",
				},
				{
					career_path: 'Creative Fields',
					description:
						"INTPs' ability to think outside the box and generate unique ideas makes them suitable for creative fields such as writing, design, or art. They can bring a fresh and innovative perspective to these fields, pushing boundaries and creating original works.",
				},
			],
			disclaimer:
				"It's important to note that while these career paths align with many INTPs' strengths, individual preferences, interests, and skills can vary. It's essential for INTPs to explore their own passions and consider how their strengths can be applied to different fields to find a fulfilling career path.",
		},
	},
	ENTJ: {
		pt_alias: 'Commander',
		description:
			'ENTJ stands for Extraverted, Intuitive, Thinking, and Judging. Individuals with the ENTJ personality type are often described as assertive, confident, and natural-born leaders. They possess a strong drive for success and are often highly ambitious. ENTJs are known for their strategic thinking, logical reasoning, and ability to make tough decisions. They are effective organizers and excel at planning and executing projects. ENTJs are often charismatic and persuasive communicators, inspiring others to follow their vision. They are comfortable taking charge and enjoy leading teams to achieve goals. While they can be focused and driven, they may also have a tendency to be impatient or direct in their communication. Overall, ENTJs are visionary leaders who thrive in challenging and dynamic environments.',
		strength_info: {
			strengths: [
				{
					strength: 'Leadership Abilities',
					description:
						'ENTJs possess natural leadership qualities and excel in positions of authority. They have a commanding presence, a clear vision for success, and the ability to inspire and motivate others. Their strategic thinking and assertiveness make them effective at making decisions, delegating tasks, and driving their teams towards achieving objectives.',
				},
				{
					strength: 'Planning and Execution Skills',
					description:
						'ENTJs are skilled at analyzing complex situations, formulating detailed plans, and executing them with precision. They have a knack for organizing resources, setting realistic goals, and efficiently managing projects. Their ability to see the big picture and break it down into actionable steps enables them to navigate complex challenges effectively.',
				},
				{
					strength: 'Strategic and Analytical Thinking',
					description:
						'ENTJs are renowned for their strategic mindset and analytical thinking. They can quickly assess situations, identify patterns, and make logical connections. This enables them to evaluate risks, anticipate outcomes, and devise effective strategies. They excel at problem-solving and finding innovative solutions by considering multiple perspectives and weighing the pros and cons.',
				},
			],
			disclaimer:
				"It's important to note that while these strengths are commonly associated with the ENTJ personality type, individuals can vary in their strengths and weaknesses regardless of their type.",
		},
		weakness_info: {
			weaknesses: [
				{
					weakness: 'Impatience and Intolerance for Inefficiency',
					description:
						'ENTJs have a strong drive for efficiency and results, which can make them impatient with slow progress or inefficiencies. They may become frustrated with individuals or processes they perceive as hindering their goals, leading to a lack of tolerance for differing work styles or ideas.',
				},
				{
					weakness: 'Tendency to be Overly Dominant',
					description:
						"ENTJs' assertive and commanding nature can sometimes result in a dominating or controlling leadership style. They may have a strong desire to be in control and struggle with delegating tasks or trusting others to take the lead. This can lead to strained relationships and hinder collaboration.",
				},
				{
					weakness: 'Difficulty Considering Emotional Factors',
					description:
						'ENTJs tend to prioritize logic and rationality over emotions. They may struggle with empathizing or understanding the emotional needs of others, focusing more on achieving objectives. This can lead to unintentional dismissiveness or insensitivity, causing interpersonal challenges or a lack of emotional support within teams.',
				},
			],
			disclaimer:
				"It's important to remember that these weaknesses are not universal to all ENTJs, and individuals may exhibit different strengths and weaknesses within their personality type. Awareness of these weaknesses can help ENTJs develop strategies to manage them and cultivate a more balanced leadership approach.",
		},
		career_path_info: {
			career_paths: [
				{
					career_path: 'Executive/Management Roles',
					description:
						"ENTJs' strong leadership abilities, strategic thinking, and assertiveness make them well-suited for executive or management positions. They can thrive in roles where they can lead teams, make high-level decisions, and drive organizational success.",
				},
				{
					career_path: 'Entrepreneurship',
					description:
						"ENTJs' ambition, drive, and ability to take charge make them excellent candidates for entrepreneurship. Their natural leadership skills and strategic mindset allow them to identify opportunities, take calculated risks, and build successful ventures.",
				},
				{
					career_path: 'Consulting/Advisory Roles',
					description:
						"ENTJs' analytical thinking, planning skills, and ability to assess complex situations make them valuable in consulting or advisory roles. They can provide strategic guidance, solve organizational challenges, and offer insights to help businesses optimize their operations.",
				},
				{
					career_path: 'Project Management',
					description:
						"ENTJs' planning and execution skills make them effective project managers. They can oversee complex projects, coordinate resources, and ensure that goals are achieved within specified timelines and budgets.",
				},
				{
					career_path: 'Law/Politics',
					description:
						"ENTJs' persuasive communication style and ability to think strategically can lead them to careers in law or politics. They can excel as lawyers, advocates, or politicians, where their assertiveness and leadership qualities can help them navigate complex systems and influence change.",
				},
			],
			disclaimer:
				"It's important to note that while these career paths align with many ENTJs' strengths, individual preferences, interests, and skills can vary. It's essential for ENTJs to explore their own passions and consider how their strengths can be applied to different fields to find a fulfilling career path.",
		},
	},
	ENTP: {
		pt_alias: 'Debater',
		description:
			'ENTP stands for Extraverted, Intuitive, Thinking, and Perceiving. Individuals with the ENTP personality type are often described as innovative, curious, and adaptable. They possess a quick intellect and enjoy exploring new ideas and possibilities. ENTPs thrive in intellectually stimulating environments and are skilled at analyzing complex concepts from different angles. They have a natural talent for generating creative solutions and challenging the status quo. ENTPs are often charismatic and enjoy engaging in debates and discussions. They are adaptable and open to change, embracing new opportunities and adjusting their approach as needed. While they can be enthusiastic and outgoing, they may also have a tendency to be indecisive or easily bored. Overall, ENTPs are imaginative thinkers who thrive on intellectual exploration and enjoy engaging with others in lively discussions.',
		strength_info: {
			strengths: [
				{
					strength: 'Quick and Agile Thinking',
					description:
						'ENTPs possess a sharp intellect and agile thinking style. They can quickly grasp complex concepts, connect disparate ideas, and generate creative solutions. Their ability to think on their feet and adapt to new information makes them excellent problem solvers.',
				},
				{
					strength: 'Innovative and Out-of-the-Box Thinking',
					description:
						'ENTPs have a natural talent for coming up with innovative ideas and approaches. They enjoy challenging conventional wisdom and exploring unconventional possibilities. Their ability to think outside the box allows them to bring fresh perspectives and generate creative solutions to problems.',
				},
				{
					strength: 'Strong Communication and Persuasion Skills',
					description:
						'ENTPs are often charismatic and have a way with words. They excel in communication, expressing their ideas with enthusiasm and clarity. Their persuasive skills allow them to effectively engage others in discussions and debates, conveying their viewpoints in a compelling manner.',
				},
			],
			disclaimer:
				"It's important to note that while these strengths are commonly associated with the ENTP personality type, individuals can vary in their strengths and weaknesses regardless of their type.",
		},
		weakness_info: {
			weaknesses: [
				{
					weakness: 'Tendency to Procrastinate',
					description:
						"ENTPs' love for exploring ideas and possibilities can sometimes lead to procrastination when it comes to completing tasks or following through on commitments. They may get easily distracted by new interests or find it challenging to maintain focus on one project at a time.",
				},
				{
					weakness: 'Dislike for Routine and Repetition',
					description:
						'ENTPs can become easily bored with routine tasks or repetitive work. They thrive on novelty and intellectual stimulation, which can make it difficult for them to stay engaged or motivated in situations that lack variety or excitement.',
				},
				{
					weakness: 'Argumentative Nature',
					description:
						'ENTPs enjoy engaging in debates and discussions, but their enthusiasm for intellectual sparring can sometimes come across as argumentative or confrontational to others. They may unintentionally challenge or question ideas without considering the potential impact on interpersonal dynamics.',
				},
			],
			disclaimer:
				"It's important to remember that these weaknesses are not universal to all ENTPs, and individuals may exhibit different strengths and weaknesses within their personality type. Awareness of these weaknesses can help ENTPs develop strategies to manage them and find a balance between their natural inclinations and the demands of different situations.",
		},
		career_path_info: {
			career_paths: [
				{
					career_path: 'Entrepreneurship',
					description:
						"ENTPs' innovative thinking, adaptability, and persuasive communication skills make them well-suited for entrepreneurship. They can thrive by creating their own ventures, where they have the freedom to explore new ideas, take calculated risks, and challenge traditional norms.",
				},
				{
					career_path: 'Creative Fields',
					description:
						"ENTPs' imaginative thinking and out-of-the-box ideas make them a good fit for creative fields such as advertising, marketing, or design. They can bring fresh perspectives, generate innovative campaigns, and create compelling content.",
				},
				{
					career_path: 'Consulting',
					description:
						"ENTPs' quick thinking, problem-solving abilities, and communication skills make them effective consultants. They can offer strategic guidance, analyze complex issues, and provide innovative solutions to a wide range of clients.",
				},
				{
					career_path: 'Research and Development',
					description:
						"ENTPs' curiosity, analytical thinking, and innovative nature can lead them to careers in research and development. They can excel in roles where they explore new technologies, conduct experiments, and contribute to cutting-edge advancements in fields like science, technology, or engineering.",
				},
				{
					career_path: 'Politics and Advocacy',
					description:
						"ENTPs' persuasive communication skills, charismatic presence, and passion for intellectual debates can make them effective politicians or advocates. They can use their ability to articulate ideas, challenge the status quo, and influence public opinion to drive change and champion causes they believe in.",
				},
			],
			disclaimer:
				"It's important to note that while these career paths align with many ENTPs' strengths, individual preferences, interests, and skills can vary. It's essential for ENTPs to explore their own passions and consider how their strengths can be applied to different fields to find a fulfilling career path.",
		},
	},
	INFJ: {
		pt_alias: 'Advocate',
		description:
			'INFJ stands for Introverted, Intuitive, Feeling, and Judging. Individuals with the INFJ personality type are often described as insightful, empathetic, and idealistic. They possess a deep understanding of emotions and an innate ability to connect with others on a profound level. INFJs are highly intuitive and value authenticity, seeking meaningful connections and personal growth. They are driven by their values and have a strong sense of purpose. INFJs often prioritize helping others and making a positive impact in the world. They are skilled at recognizing patterns, envisioning possibilities, and inspiring others towards personal and collective growth. While they can be private and reserved, they may also have a tendency to be perfectionistic or overly self-critical. Overall, INFJs are compassionate and insightful individuals who seek to create harmony and make a difference in the lives of others.',
		strength_info: {
			strengths: [
				{
					strength: 'Empathy and Understanding',
					description:
						'INFJs have a remarkable ability to empathize with others and understand their emotions and perspectives. They are highly attuned to the needs and feelings of those around them, making them excellent listeners and sources of support. Their compassionate nature allows them to create deep and meaningful connections with others.',
				},
				{
					strength: 'Insightful and Intuitive',
					description:
						'INFJs possess a strong intuition and insightful understanding of complex situations. They can often see beneath the surface and grasp underlying patterns and motivations. Their intuitive nature enables them to make connections and offer unique perspectives, which can be valuable in problem-solving and decision-making.',
				},
				{
					strength: 'Visionary and Idealistic',
					description:
						'INFJs often have a clear vision of their values and aspirations. They are driven by a strong sense of purpose and a desire to make a positive impact in the world. Their idealistic nature fuels their commitment to personal growth and inspires them to work towards creating a better future for themselves and others.',
				},
			],
			disclaimer:
				"It's important to note that while these strengths are commonly associated with the INFJ personality type, individuals can vary in their strengths and weaknesses regardless of their type.",
		},
		weakness_info: {
			weaknesses: [
				{
					weakness: 'Perfectionism and Self-Criticism',
					description:
						'INFJs can have high standards for themselves and may strive for perfection. They tend to be self-critical and may become overly focused on their perceived flaws or mistakes. This can lead to self-doubt, anxiety, and a tendency to be overly hard on themselves.',
				},
				{
					weakness: 'Difficulty Setting Boundaries',
					description:
						"INFJs' empathetic nature and desire to help others can sometimes make it challenging for them to set and enforce personal boundaries. They may prioritize the needs of others over their own, leading to feelings of being overwhelmed or taken advantage of.",
				},
				{
					weakness: 'Emotional Sensitivity and Overwhelm',
					description:
						'INFJs are deeply attuned to their emotions and the emotions of others. This sensitivity can make them more susceptible to feeling overwhelmed by intense emotions, both their own and those of others. They may struggle with emotional boundaries and may need time alone to recharge and process their feelings.',
				},
			],
			disclaimer:
				"It's important to remember that these weaknesses are not universal to all INFJs, and individuals may exhibit different strengths and weaknesses within their personality type. Awareness of these weaknesses can help INFJs develop strategies to manage them and cultivate a more balanced approach to self-care and relationships.",
		},
		career_path_info: {
			career_paths: [
				{
					career_path: 'Counseling/Psychology',
					description:
						"INFJs' empathy, understanding, and insight make them well-suited for careers in counseling or psychology. They can provide support, guidance, and help individuals navigate their emotions and personal growth.",
				},
				{
					career_path: 'Social Work/Humanitarian Work',
					description:
						"INFJs' strong sense of purpose and desire to make a positive impact make them well-suited for social work or humanitarian work. They can advocate for marginalized populations, promote social justice, and work towards creating a more equitable society.",
				},
				{
					career_path: 'Writing/Artistic Fields',
					description:
						"INFJs' introspective nature and creativity make them well-suited for careers in writing, art, or other creative fields. They can express their insights and emotions through their artistic work, inspiring and connecting with others on a deep level.",
				},
				{
					career_path: 'Nonprofit/NGO Leadership',
					description:
						"INFJs' vision and desire to contribute to the greater good make them effective leaders in nonprofit organizations or NGOs. They can lead teams, develop strategies, and drive initiatives that align with their values and make a positive impact in society.",
				},
				{
					career_path: 'Teaching/Education',
					description:
						"INFJs' empathy and passion for helping others make them well-suited for careers in teaching or education. They can inspire and mentor students, create inclusive learning environments, and make a difference in the lives of young individuals.",
				},
			],
			disclaimer:
				"It's important to note that while these career paths align with many INFJs' strengths, individual preferences, interests, and skills can vary. It's essential for INFJs to explore their own passions and consider how their strengths can be applied to different fields to find a fulfilling career path.",
		},
	},
	INFP: {
		pt_alias: 'Mediator',
		description:
			'INFP stands for Introverted, Intuitive, Feeling, and Perceiving. Individuals with the INFP personality type are often described as idealistic, sensitive, and creative. They possess a deep sense of inner values and strive for authenticity and harmony in their lives. INFPs are introspective and introspective, preferring to spend time reflecting on their thoughts and emotions. They have a strong imaginative and creative side, often expressing themselves through art, writing, or other creative outlets. INFPs deeply empathize with others and value deep connections and understanding. They are driven by their values and have a desire to make a positive impact in the world. While they can be compassionate and insightful, they may also struggle with indecisiveness or difficulty with practical matters. Overall, INFPs are empathetic and creative individuals who seek to live in alignment with their personal values and make a difference in the lives of others.',
		strength_info: {
			strengths: [
				{
					strength: 'Deep Empathy and Understanding',
					description:
						'INFPs possess a remarkable ability to empathize with others and understand their emotions and perspectives. They are highly attuned to the needs and feelings of those around them, making them compassionate and supportive individuals. Their deep empathy allows them to forge meaningful connections with others.',
				},
				{
					strength: 'Creativity and Imagination',
					description:
						'INFPs have a rich inner world and a strong creative drive. They possess a vivid imagination and often excel in artistic or creative endeavors. Their ability to think outside the box and generate unique ideas allows them to bring fresh perspectives and innovative solutions to various situations.',
				},
				{
					strength: 'Commitment to Personal Values and Authenticity',
					description:
						'INFPs have a strong sense of personal values and strive to live in alignment with them. They are authentic and genuine in their actions and interactions, valuing honesty and integrity. Their commitment to their values gives them a strong moral compass and a drive to make a positive impact in the world.',
				},
			],
			disclaimer: "It's important to note that while these strengths are commonly associated with the INFP personality type, individuals can vary in their strengths and weaknesses regardless of their type.",
		},
		weakness_info: {
			weaknesses: [
				{
					weakness: 'Tendency to be overly idealistic',
					description:
						"INFPs have a strong inclination towards idealism, which can sometimes lead to disappointment or frustration when reality doesn't align with their lofty expectations. They may struggle with accepting compromises or navigating practical challenges that conflict with their ideals.",
				},
				{
					weakness: 'Difficulty with Decision-Making',
					description:
						"INFPs' preference for considering multiple perspectives and their desire to avoid conflict can make decision-making challenging. They may overanalyze options, fear making the wrong choice, or seek external validation, which can lead to indecisiveness and a lack of assertiveness.",
				},
				{
					weakness: 'Sensitivity to Criticism',
					description:
						'INFPs are often highly sensitive to criticism and can take it deeply to heart. They may internalize negative feedback or perceive criticism as a personal attack. This sensitivity can make it challenging for them to handle constructive criticism and may lead to self-doubt or avoidance of situations where criticism may arise.',
				},
			],
			disclaimer: "It's important to remember that these weaknesses are not universal to all INFPs, and individuals may exhibit different strengths and weaknesses within their personality type. Awareness of these weaknesses can help ENFJs develop strategies to manage them and cultivate a more balanced approach to self-care and relationships.",
		},
		career_path_info: {
			career_paths: [
				{
					career_path: 'Counseling/Psychology',
					description:
						"INFPs' deep empathy, understanding, and strong value system make them well-suited for careers in counseling or psychology. They can provide support, guidance, and help individuals navigate their emotions and personal growth.",
				},
				{
					career_path: 'Creative Fields',
					description:
						" INFPs' rich imagination and strong creative drive make them well-suited for careers in art, writing, music, or other creative fields. They can express their unique perspectives and emotions through their artistic work and make a profound impact on others.",
				},
				{
					career_path: 'Social Work/Humanitarian Work',
					description:
						"INFPs' deep empathy, compassion, and commitment to making a positive impact make them well-suited for social work or humanitarian work. They can advocate for marginalized populations, promote social justice, and work towards creating a more equitable and compassionate society.",
				},
				{
					career_path: 'Nonprofit/NGO Work',
					description:
						"INFPs' strong value system and desire to make a difference in the world make them effective in nonprofit organizations or NGOs. They can work towards causes they believe in, foster positive change, and contribute to the betterment of society.",
				},
				{
					career_path: 'Writing/Journalism',
					description:
						"INFPs' introspective nature and talent for expressing themselves make them well-suited for careers in writing, journalism, or blogging. They can communicate their insights, share stories that resonate with others, and create meaningful connections through their written work.",
				},
			],
			disclaimer:
				"It's important to note that while these career paths align with many INFPs' strengths, individual preferences, interests, and skills can vary. It's essential for INFPs to explore their own passions and consider how their strengths can be applied to different fields to find a fulfilling career path.",
		},
	},
	ENFJ: {
		pt_alias: 'Giver',
		description:
			'ENFJ stands for Extraverted, Intuitive, Feeling, and Judging. Individuals with the ENFJ personality type are often described as warm, charismatic, and empathetic. They possess excellent interpersonal skills and a natural ability to connect with others. ENFJs are driven by their values and have a strong desire to help and uplift those around them. They are highly attuned to the emotions and needs of others and are skilled at providing support and guidance. ENFJs are natural leaders and are often found in roles where they can inspire and motivate others towards a common goal. They excel at bringing people together, fostering harmony, and creating a positive and inclusive environment. While they thrive in social settings, they may sometimes neglect their own needs or struggle with setting boundaries. Overall, ENFJs are compassionate and influential individuals who strive to make a meaningful impact in the lives of others.',
		strength_info: {
			strengths: [
				{
					strength: 'Empathy and Understanding',
					description:
						'ENFJs possess a remarkable ability to understand and empathize with others. They are highly attuned to the emotions and needs of those around them, and they genuinely care about the well-being of others. This empathy allows them to create deep and meaningful connections and provide support and guidance.',
				},
				{
					strength: 'Charismatic Leadership',
					description:
						' ENFJs have a natural charisma and the ability to inspire and motivate others. They excel in leadership roles where they can bring people together, communicate effectively, and guide others towards a common goal. Their passion and enthusiasm are contagious, making them influential leaders.',
				},
				{
					strength: 'Strong Interpersonal Skills',
					description:
						'ENFJs are skilled at building and maintaining relationships. They have excellent communication skills and are adept at understanding and responding to the emotions and perspectives of others. They create a warm and inclusive environment where people feel valued and heard.',
				},
			],
			disclaimer:
				"It's important to note that while these strengths are commonly associated with the ENFJ personality type, individuals can vary in their strengths and weaknesses regardless of their type.",
		},
		weakness_info: {
			weaknesses: [
				{
					weakness: 'Overextending Themselves',
					description:
						'ENFJs have a strong desire to help and please others, which can sometimes lead to overextending themselves. They may prioritize the needs of others over their own and neglect self-care. This can result in feeling overwhelmed or burnt out.',
				},
				{
					weakness: 'Difficulty Setting Boundaries',
					description:
						"ENFJs' empathetic nature and desire to please others can make it challenging for them to set and enforce personal boundaries. They may struggle with saying no or asserting their own needs, which can lead to feelings of being taken advantage of or being overwhelmed by the demands of others.",
				},
				{
					weakness: 'Emotional Sensitivity',
					description:
						"ENFJs are highly attuned to emotions, both their own and others'. While this sensitivity allows them to connect deeply with others, it can also make them more susceptible to emotional fluctuations. They may be more prone to stress, anxiety, or feeling overwhelmed by intense emotions.",
				},
			],
			disclaimer:
				"It's important to remember that these weaknesses are not universal to all ENFJs, and individuals may exhibit different strengths and weaknesses within their personality type. Awareness of these weaknesses can help ENFJs develop strategies to manage them and cultivate a more balanced approach to self-care and relationships.",
		},
		career_path_info: {
			career_paths: [
				{
					career_path: 'Counseling/Psychology',
					description:
						"ENFJs' empathy, understanding, and excellent interpersonal skills make them well-suited for careers in counseling or psychology. They can provide support, guidance, and help individuals navigate their emotions and personal growth.",
				},
				{
					career_path: 'Human Resources/People Management',
					description:
						"ENFJs' charismatic leadership, strong interpersonal skills, and ability to create a positive work environment make them effective in roles such as human resources or people management. They can foster collaboration, motivate teams, and ensure the well-being of employees.",
				},
				{
					career_path: 'Nonprofit/NGO Leadership',
					description:
						"ENFJs' passion for making a positive impact and their ability to inspire and motivate others make them effective leaders in nonprofit organizations or NGOs. They can advocate for causes they believe in, mobilize resources, and drive meaningful change.",
				},
				{
					career_path: 'Education/Administration',
					description:
						"ENFJs' ability to connect with others, their leadership skills, and their desire to uplift and inspire make them well-suited for roles in education or administration. They can create inclusive learning environments, lead teams, and shape educational policies.",
				},
				{
					career_path: 'Event Planning/Public Relations',
					description:
						"ENFJs' charisma, excellent communication skills, and ability to bring people together make them well-suited for roles in event planning or public relations. They can coordinate and organize events, build strong relationships with stakeholders, and effectively promote brands or causes.",
				},
			],
			disclaimer:
				"It's important to note that while these career paths align with many ENFJs' strengths, individual preferences, interests, and skills can vary. It's essential for ENFJs to explore their own passions and consider how their strengths can be applied to different fields to find a fulfilling career path.",
		},
	},
	ENFP: {
		pt_alias: 'Champion',
		description:
			'ENFP stands for Extraverted, Intuitive, Feeling, and Perceiving. Individuals with the ENFP personality type are often described as enthusiastic, creative, and warm-hearted. They possess a strong sense of curiosity and a deep desire to explore new ideas and possibilities. ENFPs are highly sociable and thrive in social interactions, bringing energy and enthusiasm to their interactions with others. They are insightful and perceptive, often seeing connections and patterns that others might miss. ENFPs are deeply in touch with their emotions and the emotions of those around them, and they strive for authenticity and meaning in their lives. They value personal growth, harmony, and making a positive impact on the world. While they are imaginative and adaptable, they may sometimes struggle with maintaining focus or making decisions. Overall, ENFPs are passionate and empathetic individuals who bring creativity and a zest for life to everything they do.',
		strength_info: {
			strengths: [
				{
					strength: 'Creativity and Imagination',
					description:
						'ENFPs have a rich inner world and a strong creative drive. They are imaginative and often excel in artistic or creative pursuits. They can generate unique ideas, think outside the box, and bring a fresh perspective to various situations.',
				},
				{
					strength: 'Empathy and Emotional Intelligence',
					description:
						"ENFPs have a remarkable ability to understand and connect with the emotions of others. They are highly empathetic and compassionate, making them great listeners and sources of support. They can easily relate to others' experiences and offer genuine understanding and guidance.",
				},
				{
					strength: 'Enthusiasm and Optimism',
					description:
						'ENFPs are known for their infectious enthusiasm and positive outlook on life. They bring a sense of energy and excitement to their pursuits, inspiring and motivating those around them. Their optimism helps them navigate challenges and maintain a hopeful attitude.',
				},
			],
			disclaimer:
				"It's important to note that while these strengths are commonly associated with the ENFP personality type, individuals can vary in their strengths and weaknesses regardless of their type.",
		},
		weakness_info: {
			weaknesses: [
				{
					weakness: 'Difficulty with Focus and Follow-Through',
					description:
						'ENFPs tend to be enthusiastic about exploring new ideas and possibilities, which can make it challenging for them to stay focused on one task or project for an extended period. They may struggle with follow-through and completing tasks once the initial excitement wears off.',
				},
				{
					weakness: 'Sensitivity to Criticism',
					description:
						'ENFPs are highly attuned to the emotions and opinions of others, making them sensitive to criticism. They may take criticism personally and have a strong emotional reaction to it. This sensitivity can sometimes hinder their ability to receive constructive feedback or navigate conflict.',
				},
				{
					weakness: 'Tendency to Overcommit',
					description:
						'ENFPs have a tendency to say "yes" to numerous opportunities and commitments due to their enthusiasm and desire to explore different options. However, this can lead to spreading themselves too thin and feeling overwhelmed by the multitude of responsibilities and expectations.',
				},
			],
			disclaimer:
				"It's important to remember that these weaknesses are not universal to all ENFPs, and individuals may exhibit different strengths and weaknesses within their personality type. Awareness of these weaknesses can help ENFPs develop strategies to manage them and cultivate a more balanced approach to personal growth and decision-making.",
		},
		career_path_info: {
			career_paths: [
				{
					career_path: 'Creative Fields',
					description:
						"ENFPs' imagination, creativity, and ability to think outside the box make them well-suited for careers in art, writing, design, or other creative fields. They can express their unique perspectives and bring their imaginative ideas to life.",
				},
				{
					career_path: 'Counseling/Therapy',
					description:
						"ENFPs' empathy, emotional intelligence, and genuine concern for others make them well-suited for careers in counseling or therapy. They can provide support, guidance, and help individuals navigate their emotions and personal growth.",
				},
				{
					career_path: 'Social Work/Nonprofit',
					description:
						"ENFPs' compassion, enthusiasm, and desire to make a positive impact on the world make them effective in social work or nonprofit organizations. They can advocate for causes they believe in, support marginalized populations, and drive meaningful change.",
				},
				{
					career_path: 'Teaching/Education',
					description:
						"ENFPs' passion for learning, creativity, and ability to connect with others make them well-suited for careers in teaching or education. They can inspire and motivate students, create engaging learning environments, and foster personal growth.",
				},
				{
					career_path: 'Event Planning/Public Relations',
					description:
						"ENFPs' energy, charisma, and ability to bring people together make them effective in event planning or public relations roles. They can organize and coordinate events, build strong relationships with stakeholders, and effectively communicate messages or promote causes.",
				},
			],
			disclaimer:
				"It's important to note that while these career paths align with many ENFPs' strengths, individual preferences, interests, and skills can vary. It's essential for ENFPs to explore their own passions and consider how their strengths can be applied to different fields to find a fulfilling career path.",
		},
	},
	"ISTJ": {
		"pt_alias": "Inspector",
		"description": "ISTJ stands for Introverted, Sensing, Thinking, and Judging. Individuals with the ISTJ personality type are known for their practicality, reliability, and focus on facts. They are responsible, detail-oriented, and value tradition and order. ISTJs prefer structure and organization, often excelling in planning and executing tasks with precision.",
		"strength_info": {
			"strengths": [
				{
					"strength": "Dependable and Responsible",
					"description": "ISTJs are known for their reliability and commitment to duty. They take their responsibilities seriously and are dependable in both personal and professional contexts."
				},
				{
					"strength": "Detail-Oriented and Practical",
					"description": "ISTJs have a keen eye for detail and excel in analyzing data and facts. Their practical approach helps them solve problems effectively."
				},
				{
					"strength": "Organized and Structured",
					"description": "With a natural preference for order, ISTJs are adept at creating and following systematic processes, ensuring tasks are completed efficiently."
				}
			],
			"disclaimer": "These strengths highlight typical characteristics of ISTJs, though individual strengths may vary."
		},
		"weakness_info": {
			"weaknesses": [
				{
					"weakness": "Overly Reserved",
					"description": "ISTJs can sometimes be overly reserved and may struggle with expressing their emotions, which can hinder interpersonal relationships."
				},
				{
					"weakness": "Resistance to Change",
					"description": "A strong preference for tradition and order can sometimes lead ISTJs to resist change, even when new methods could be beneficial."
				},
				{
					"weakness": "Rigidity",
					"description": "Their focus on structure and routine can make ISTJs inflexible, sometimes leading to challenges when unexpected situations arise."
				}
			],
			"disclaimer": "These weaknesses are common in ISTJs but do not define every individual with this personality type."
		},
		"career_path_info": {
			"career_paths": [
				{
					"career_path": "Accounting and Finance",
					"description": "ISTJs' attention to detail and methodical approach make them well-suited for careers in accounting, auditing, and finance, where precision is key."
				},
				{
					"career_path": "Law Enforcement and Military",
					"description": "Their discipline and respect for rules often lead ISTJs to excel in structured environments such as law enforcement or the military."
				},
				{
					"career_path": "Engineering and Technical Fields",
					"description": "ISTJs' logical and analytical skills are valuable in engineering and technical fields, where systematic problem-solving is essential."
				},
				{
					"career_path": "Administration and Management",
					"description": "ISTJs are often successful in administrative roles where organization, planning, and adherence to procedures are vital."
				}
			],
			"disclaimer": "These career suggestions are general guidelines and should be considered alongside personal interests and values."
		}
	},
	ISFJ: {
		pt_alias: 'Protector',
		description:
			'ISFJ stands for Introverted, Sensing, Feeling, and Judging. Individuals with the ISFJ personality type are often described as conscientious, dependable, and nurturing. They are practical and detail-oriented, paying close attention to the present reality and focusing on concrete facts and information. ISFJs are known for their strong sense of duty and responsibility, often putting the needs of others before their own. They are empathetic and caring, seeking harmony and stability in their relationships. ISFJs value tradition and are loyal to their commitments. They have a strong memory for details and are adept at organizing and maintaining order. While they excel at practical tasks, they may sometimes struggle with asserting their own needs and setting boundaries. Overall, ISFJs are reliable and caring individuals who bring a sense of warmth and stability to their relationships and responsibilities.',
		strength_info: {
			strengths: [
				{
					strength: 'Dependability and Reliability',
					description:
						'ISFJs are known for their strong sense of duty and commitment. They take their responsibilities seriously and can be relied upon to fulfill their obligations. Others often see them as dependable and trustworthy individuals.',
				},
				{
					strength: 'Empathy and Compassion',
					description:
						'ISFJs have a natural ability to understand and empathize with the emotions and needs of others. They genuinely care about the well-being of those around them and are often supportive and nurturing. Their compassionate nature makes them excellent listeners and sources of comfort.',
				},
				{
					strength: 'Attention to Detail and Organizational Skills',
					description:
						"ISFJs have a keen eye for detail and are skilled at organizing information and tasks. They excel in creating and maintaining order, whether it's in their personal lives or professional environments. Their meticulous approach ensures that important details are not overlooked.",
				},
			],
			disclaimer:
				"It's important to note that while these strengths are commonly associated with the ISFJ personality type, individuals can vary in their strengths and weaknesses regardless of their type.",
		},
		weakness_info: {
			weaknesses: [
				{
					weakness: 'Difficulty Setting Boundaries',
					description:
						'ISFJs\' strong desire to please and help others can make it challenging for them to set boundaries. They may have difficulty saying "no" or asserting their own needs, often putting others\' needs ahead of their own. This can lead to feelings of being overwhelmed or taken advantage of.',
				},
				{
					weakness: 'Tendency to Avoid Conflict',
					description:
						' ISFJs prefer harmony and avoid conflict whenever possible. They may struggle with expressing their own opinions or standing up for themselves in order to maintain peace. This can result in suppressing their own needs or opinions, which can lead to dissatisfaction or resentment over time.',
				},
				{
					weakness: 'Perfectionism and Self-Criticism',
					description:
						'ISFJs have high standards for themselves and others, and they may be overly critical of their own performance. They strive for perfection and can be hard on themselves when they feel they have fallen short. This self-criticism can create undue stress and hinder their ability to recognize their own achievements.',
				},
			],
			disclaimer:
				"It's important to remember that these weaknesses are not universal to all ISFJs, and individuals may exhibit different strengths and weaknesses within their personality type. Awareness of these weaknesses can help ISFJs develop strategies to manage them and cultivate a healthier balance between caring for others and attending to their own needs.",
		},
		career_path_info: {
			career_paths: [
				{
					career_path: 'Healthcare and Nursing',
					description:
						"ISFJs' caring nature, attention to detail, and reliability make them well-suited for roles in healthcare and nursing. They excel in providing compassionate care and ensuring the well-being of others.",
				},
				{
					career_path: 'Education and Counseling',
					description:
						"ISFJs' empathy, patience, and ability to listen make them effective in roles within education or counseling. They can support and guide students or individuals, create nurturing environments, and help others achieve their potential.",
				},
				{
					career_path: 'Administrative and Organizational Roles',
					description:
						"ISFJs' organizational skills, attention to detail, and dependability make them well-suited for administrative and organizational roles. They excel in tasks that require structure, planning, and keeping things in order.",
				},
				{
					career_path: 'Social Work and Human Resources',
					description:
						"ISFJs' empathy, compassion, and desire to help others make them effective in roles within social work or human resources. They can provide support to individuals, resolve conflicts, and ensure a positive work environment.",
				},
				{
					career_path: 'Hospitality and Customer Service',
					description:
						"ISFJs' attentive and nurturing nature makes them well-suited for roles in the hospitality industry and customer service. They excel in providing excellent service, anticipating the needs of others, and creating a welcoming environment.",
				},
			],
			disclaimer:
				'These career paths align with many of the strengths of ISFJs, such as their dependability, empathy, and attention to detail. However, individual preferences, interests, and skills should also be considered when choosing a career path.',
		},
	},
	ESTJ: {
		pt_alias: 'Director',
		description:
			'ESTJ stands for Extraverted, Sensing, Thinking, and Judging. Individuals with the ESTJ personality type are often described as practical, organized, and efficient. They are driven by a strong sense of responsibility and take their commitments seriously. ESTJs are focused on the present reality and rely on factual information to make decisions. They value order, structure, and following established rules and procedures. They excel in planning, organizing, and executing tasks effectively. ESTJs are assertive and direct in their communication, and they value efficiency and productivity. While they excel in practical matters, they may sometimes struggle with considering alternative perspectives or adapting to change. Overall, ESTJs are dependable and determined individuals who bring a sense of structure and effectiveness to their environments and responsibilities.',
		strength_info: {
			strengths: [
				{
					strength: 'Strong Leadership Skills',
					description:
						'ESTJs possess natural leadership qualities and excel in taking charge of situations. They are decisive, assertive, and adept at organizing and delegating tasks. Their practical and logical approach allows them to effectively guide and motivate others.',
				},
				{
					strength: 'Dependability and Reliability',
					description:
						'ESTJs are known for their strong work ethic and sense of responsibility. They can be counted on to fulfill their commitments and meet deadlines. Others often see them as reliable and trustworthy individuals who consistently deliver results.',
				},
				{
					strength: 'Excellent Organizational Skills',
					description:
						'ESTJs have a knack for creating order and structure in their environments. They excel at organizing information, resources, and processes, making them efficient and effective in managing projects or teams. Their attention to detail ensures that nothing falls through the cracks.',
				},
			],
			disclaimer:
				"It's important to note that while these strengths are commonly associated with the ESTJ personality type, individuals can vary in their strengths and weaknesses regardless of their type.",
		},
		weakness_info: {
			weaknesses: [
				{
					weakness: 'Inflexibility and Resistance to Change',
					description:
						'ESTJs prefer stability and predictability and may be resistant to change. They can become frustrated or uncomfortable when faced with unexpected or unstructured situations. This inflexibility can hinder their ability to adapt to new ideas or perspectives.',
				},
				{
					weakness: 'Bluntness and Directness',
					description:
						'ESTJs tend to be straightforward and direct in their communication style. While this can be seen as a strength in some situations, it may come across as harsh or insensitive to others. They may struggle with delivering feedback or criticism in a more tactful manner.',
				},
				{
					weakness: 'Difficulty Considering Alternative Perspectives',
					description:
						'ESTJs have a strong preference for their own ideas and opinions, which can make it challenging for them to consider alternative perspectives. They may be less open to different viewpoints or approaches, preferring to stick to established methods and practices.',
				},
			],
			disclaimer:
				"It's important to remember that these weaknesses are not universal to all ESTJs, and individuals may exhibit different strengths and weaknesses within their personality type. Awareness of these weaknesses can help ESTJs develop strategies to manage them and cultivate a more balanced approach to change, communication, and considering different perspectives.",
		},
		career_path_info: {
			career_paths: [
				{
					career_path: 'Management and Leadership Roles',
					description:
						"ESTJs' natural leadership skills, organizational abilities, and assertiveness make them well-suited for management and leadership positions. They excel in providing structure, making decisions, and driving teams towards goals.",
				},
				{
					career_path: 'Project Management',
					description:
						"ESTJs' strong organizational skills, attention to detail, and ability to handle multiple tasks make them effective in project management roles. They excel in planning, organizing, and executing projects, ensuring that tasks are completed efficiently and on time.",
				},
				{
					career_path: 'Law Enforcement and Military',
					description:
						"ESTJs' sense of duty, adherence to rules, and strong work ethic make them well-suited for careers in law enforcement or the military. They thrive in structured environments, value order and discipline, and are dedicated to upholding the law and protecting others.",
				},
				{
					career_path: 'Business Administration',
					description:
						"ESTJs' practicality, strategic thinking, and ability to make tough decisions make them effective in business administration roles. They excel in managing budgets, resources, and processes, ensuring the smooth operation of organizations.",
				},
				{
					career_path: 'Healthcare Administration',
					description:
						"ESTJs' attention to detail, organizational skills, and sense of responsibility make them well-suited for healthcare administration roles. They can effectively manage healthcare facilities, ensure compliance with regulations, and coordinate administrative functions.",
				},
			],
			disclaimer:
				'These career paths align with many of the strengths of ESTJs, such as their leadership abilities, organizational skills, and strong sense of responsibility. However, individual preferences, interests, and skills should also be considered when choosing a career path.',
		},
	},
	ESFJ: {
		pt_alias: 'Caregiver',
		description:
			'ESFJ stands for Extraverted, Sensing, Feeling, and Judging. Individuals with the ESFJ personality type are often described as warm, friendly, and conscientious. They are outgoing and enjoy being around people, seeking harmony and connection in their relationships. ESFJs are observant of their surroundings and rely on their senses to gather information. They make decisions based on personal values and emotions, often considering the impact on others. ESFJs are dependable and responsible, placing a strong emphasis on fulfilling their duties. They are often nurturing and supportive, taking care of the needs of others. While they excel at creating a harmonious environment, they may sometimes struggle with setting boundaries and prioritizing their own needs. Overall, ESFJs are sociable and caring individuals who bring a sense of warmth and harmony to their relationships and responsibilities.',
		strength_info: {
			strengths: [
				{
					strength: 'Strong Interpersonal Skills',
					description:
						'ESFJs are naturally warm and friendly, making it easy for them to connect with others. They excel in building and maintaining relationships, often being approachable and supportive. Their ability to understand and meet the needs of others contributes to their effectiveness in social settings.',
				},
				{
					strength: 'Empathy and Compassion',
					description:
						'ESFJs have a deep sense of empathy and genuinely care about the well-being of others. They are attentive to the emotions and needs of those around them and are often quick to offer support and assistance. Their nurturing nature makes them excellent at providing comfort and understanding.',
				},
				{
					strength: 'Dependability and Responsibility',
					description:
						'ESFJs take their commitments seriously and are known for their reliability. They are responsible individuals who can be counted on to fulfill their obligations. Their conscientiousness and dedication make them valuable team members and trustworthy friends.',
				},
			],
			disclaimer:
				"It's important to note that while these strengths are commonly associated with the ESFJ personality type, individuals can vary in their strengths and weaknesses regardless of their type.",
		},
		weakness_info: {
			weaknesses: [
				{
					weakness: 'Difficulty Setting Boundaries',
					description:
						'ESFJs have a strong desire to please others and may struggle with setting boundaries. They may find it challenging to say "no" or prioritize their own needs, often putting others before themselves. This can lead to feelings of being overwhelmed or taken advantage of.',
				},
				{
					weakness: 'Tendency to Avoid Conflict',
					description:
						'ESFJs value harmony and may avoid conflict at all costs. They may find it difficult to express their own opinions or assert themselves in order to maintain peace. This can result in suppressing their own needs or opinions, which can lead to internal dissatisfaction or resentment.',
				},
				{
					weakness: 'Overemphasis on Approval',
					description:
						'ESFJs have a strong need for external validation and may seek approval from others. They may place too much emphasis on pleasing others or conforming to societal expectations, sometimes neglecting their own desires or values in the process. This can lead to a lack of personal fulfillment and a diminished sense of self.',
				},
			],
			disclaimer:
				"It's important to remember that these weaknesses are not universal to all ESFJs, and individuals may exhibit different strengths and weaknesses within their personality type. Awareness of these weaknesses can help ESFJs develop strategies to manage them and strike a healthier balance between caring for others and attending to their own needs.",
		},
		career_path_info: {
			career_paths: [
				{
					career_path: 'Healthcare and Social Services',
					description:
						"ESFJs' strong interpersonal skills, empathy, and nurturing nature make them well-suited for careers in healthcare and social services. They excel in roles that involve providing care, support, and guidance to others, such as nursing, counseling, or social work.",
				},
				{
					career_path: 'Human Resources and Customer Service',
					description:
						"ESFJs' ability to connect with people, resolve conflicts, and create a harmonious environment makes them effective in human resources and customer service roles. They excel in understanding and meeting the needs of both employees and customers, ensuring positive interactions and maintaining strong relationships.",
				},
				{
					career_path: 'Education and Training',
					description:
						"ESFJs' natural warmth, friendliness, and ability to connect with others make them well-suited for careers in education and training. They excel in creating supportive and engaging learning environments, facilitating understanding, and helping others develop new skills.",
				},
				{
					career_path: 'Event Planning and Hospitality',
					description:
						"ESFJs' attention to detail, organizational skills, and desire to create a welcoming atmosphere make them well-suited for event planning and hospitality careers. They excel in coordinating and executing events, ensuring that all aspects are well-managed and attendees have a positive experience.",
				},
				{
					career_path: 'Administrative and Office Support',
					description:
						"ESFJs' dependability, conscientiousness, and strong work ethic make them effective in administrative and office support roles. They excel in organizing and managing tasks, providing support to teams, and ensuring the smooth operation of day-to-day activities.",
				},
			],
			disclaimer:
				'These career paths align with many of the strengths of ESFJs, such as their interpersonal skills, empathy, and sense of responsibility. However, individual preferences, interests, and skills should also be considered when choosing a career path.',
		},
	},
	ISTP: {
		pt_alias: 'Crafter',
		description:
			'ISTP stands for Introverted, Sensing, Thinking, and Perceiving. Individuals with the ISTP personality type are often described as practical, independent, and analytical. They are introverted and prefer to spend time alone or with a few close friends. ISTPs are highly observant and rely on their senses to gather information about the world around them. They are logical and rational thinkers, using objective analysis to solve problems. ISTPs value their freedom and autonomy, often enjoying hands-on activities and exploring new experiences. They are adaptable and spontaneous, preferring to live in the present moment. While they excel in practical matters, they may sometimes struggle with expressing their emotions or dealing with long-term planning. Overall, ISTPs are resourceful and independent individuals who bring a sense of logic and spontaneity to their experiences and responsibilities.',
		strength_info: {
			strengths: [
				{
					strength: 'Practical and Resourceful',
					description:
						'ISTPs are highly practical individuals who excel in finding practical solutions to problems. They have a knack for troubleshooting and fixing mechanical or technical issues. Their resourcefulness allows them to make the most of the resources available to them, often finding creative and efficient solutions.',
				},
				{
					strength: 'Adaptability and Spontaneity',
					description:
						'ISTPs are highly adaptable and can easily adjust to new situations and environments. They thrive in unpredictable or fast-paced settings, often remaining calm and composed under pressure. Their spontaneous nature allows them to embrace new experiences and take risks.',
				},
				{
					strength: 'ndependent and Self-Reliant',
					description:
						"ISTPs value their independence and enjoy working autonomously. They are self-reliant individuals who prefer to tackle challenges on their own. They have a strong sense of self-confidence and trust in their own abilities, often relying on their practical skills and knowledge to navigate through life's complexities.",
				},
			],
			disclaimer:
				"It's important to note that while these strengths are commonly associated with the ISTP personality type, individuals can vary in their strengths and weaknesses regardless of their type.",
		},
		weakness_info: {
			weaknesses: [
				{
					weakness: 'Difficulty Expressing Emotions',
					description:
						'ISTPs tend to be more reserved when it comes to expressing their emotions. They may struggle with articulating their feelings or connecting with others on an emotional level. This can sometimes lead to misunderstandings or a sense of emotional distance in their relationships.',
				},
				{
					weakness: 'Impulsiveness and Risk-taking',
					description:
						'ISTPs are known for their spontaneous nature and willingness to take risks. While this can be exciting and adventurous, it can also lead to impulsive decision-making without fully considering the potential consequences. They may jump into actions without thoroughly analyzing the long-term impact.',
				},
				{
					weakness: 'Tendency to Avoid Commitments',
					description:
						'ISTPs value their freedom and independence, which can sometimes make them hesitant to commit to long-term plans or relationships. They prefer to keep their options open and may resist making definitive decisions or commitments until they feel confident in the outcome. This can sometimes be seen as indecisiveness or a lack of commitment by others.',
				},
			],
			disclaimer:
				"It's important to remember that these weaknesses are not universal to all ISTPs, and individuals may exhibit different strengths and weaknesses within their personality type. Awareness of these weaknesses can help ISTPs develop strategies to manage them and find a balance between spontaneity, emotional expression, and commitment.",
		},
		career_path_info: {
			career_paths: [
				{
					career_path: 'Skilled Trades and Technical Fields',
					description:
						"ISTPs' practicality, resourcefulness, and hands-on skills make them well-suited for careers in skilled trades and technical fields. They excel in occupations such as mechanics, electricians, technicians, or engineering, where they can apply their logical thinking and problem-solving abilities to practical tasks.",
				},
				{
					career_path: 'Emergency Services and Law Enforcement',
					description:
						"ISTPs' ability to remain calm under pressure, adaptability, and quick decision-making skills make them well-suited for careers in emergency services and law enforcement. They can thrive in roles such as firefighters, paramedics, police officers, or search and rescue professionals, where they can use their practical skills and quick reflexes to handle unpredictable situations.",
				},
				{
					career_path: 'Sports and Outdoor Activities',
					description:
						"ISTPs' love for hands-on experiences and physical challenges can lead them to careers in sports and outdoor activities. They may excel in roles such as athletes, coaches, adventure guides, or outdoor instructors, where they can utilize their physical abilities, adaptability, and love for exploring new environments.",
				},
				{
					career_path: 'Technical and Engineering Fields',
					description:
						"ISTPs' analytical thinking, problem-solving skills, and practicality make them well-suited for careers in technical and engineering fields. They may thrive in roles such as computer programming, engineering, or technical design, where they can apply their logical mindset and technical knowledge to develop innovative solutions.",
				},
				{
					career_path: 'Freelancing or Entrepreneurship',
					description:
						"ISTPs' independent and self-reliant nature, coupled with their practical skills and problem-solving abilities, can make them well-suited for freelancing or entrepreneurship. They can leverage their expertise in a specific area, such as graphic design, web development, or technical consulting, to offer their services independently. The flexibility and autonomy of freelancing or running their own business can cater to their desire for independence and freedom.",
				},
			],
			disclaimer:
				'These career paths align with many of the strengths of ISTPs, such as their practicality, resourcefulness, adaptability, and hands-on skills. However, individual preferences, interests, and skills should also be considered when choosing a career path.',
		},
	},
	ISFP: {
		pt_alias: 'Artist',
		description:
			'ISFP stands for Introverted, Sensing, Feeling, and Perceiving. Individuals with the ISFP personality type are often described as gentle, artistic, and sensitive. They are introverted and enjoy spending time alone or in small, intimate settings. ISFPs are highly attuned to their senses and have a keen awareness of the present moment. They are deeply in touch with their emotions and value harmony and empathy in their relationships. ISFPs have a creative and artistic side, expressing themselves through various forms of art, such as music, painting, or writing. They have a flexible and adaptable approach to life, preferring to go with the flow rather than rigidly adhering to plans. While they possess many strengths, ISFPs may sometimes struggle with decision-making or assertiveness. Overall, ISFPs bring a sense of empathy, artistic expression, and adaptability to their interactions and pursuits in life.',
		strength_info: {
			strengths: [
				{
					strength: 'Artistic and Creative Expression',
					description:
						'ISFPs have a natural inclination towards creativity and artistic expression. They possess a keen aesthetic sense and excel in various artistic pursuits such as painting, music, writing, or crafting. They use their creativity to communicate their inner thoughts and emotions, creating meaningful and inspiring works of art.',
				},
				{
					strength: 'Sensitivity and Empathy',
					description:
						"ISFPs are highly attuned to the emotions of others and possess a strong sense of empathy. They have a genuine concern for the well-being of those around them and are skilled at understanding and supporting others' emotions. Their sensitivity allows them to establish deep connections and create harmonious relationships.",
				},
				{
					strength: 'Adaptability and Flexibility',
					description:
						'ISFPs are adaptable individuals who thrive in changing environments. They are open-minded and have a natural ability to adjust their plans and preferences as per the situation. They embrace new experiences and are willing to explore different paths, making them versatile and resilient in the face of challenges.',
				},
			],
			disclaimer:
				"These strengths contribute to the ISFP's ability to create beautiful art, connect with others on an emotional level, and navigate through diverse situations with flexibility and empathy. However, it's essential to recognize that individual strengths may vary among ISFPs, and personal interests and experiences can further shape their talents and abilities.",
		},
		weakness_info: {
			weaknesses: [
				{
					weakness: 'Difficulty with Decision-making',
					description:
						'ISFPs may struggle with making decisions, especially when it comes to practical matters or long-term planning. Their desire to keep their options open and their aversion to conflict can sometimes lead to indecisiveness or difficulty committing to a specific course of action.',
				},
				{
					weakness: 'Tendency to Avoid Conflict',
					description:
						'ISFPs value harmony and may go to great lengths to avoid conflict or confrontation. While this can contribute to a peaceful environment, it may also prevent them from addressing important issues or asserting their own needs and boundaries. They may prioritize maintaining peace over expressing their own opinions or engaging in difficult conversations.',
				},
				{
					weakness: 'Sensitivity to Criticism',
					description:
						'ISFPs are sensitive individuals who can be deeply affected by criticism or negative feedback. They may take criticism personally and struggle with handling it objectively. This sensitivity can hinder their growth and development, as they may avoid situations where they fear being judged or criticized.',
				},
			],
			disclaimer:
				"It's important to note that these weaknesses are not universal to all ISFPs, and individuals may exhibit different strengths and weaknesses within their personality type. Awareness of these challenges can help ISFPs develop strategies to improve decision-making skills, address conflict constructively, and build resilience in the face of criticism.",
		},
		career_path_info: {
			career_paths: [
				{
					career_path: 'Fine Arts and Design',
					description:
						"ISFPs' artistic and creative strengths make them well-suited for careers in fine arts, such as painting, sculpting, or photography. They can also excel in design-related fields, such as graphic design or interior design, where they can express their aesthetic sensibilities and bring their artistic vision to life.",
				},
				{
					career_path: 'Counseling and Therapy',
					description:
						"ISFPs' sensitivity and empathy make them well-suited for careers in counseling or therapy. They have a natural ability to connect with others on an emotional level and provide support and guidance. Their compassionate nature and understanding of human emotions can help individuals navigate through challenging situations and foster personal growth.",
				},
				{
					career_path: 'Naturalist and Environmentalist',
					description:
						"ISFPs' deep connection with nature and their adaptable nature make them suitable for careers in environmentalism or naturalist roles. They can contribute to conservation efforts, ecological research, or outdoor education, leveraging their sensitivity to the environment and their ability to adapt to different settings.",
				},
				{
					career_path: 'Performing Arts and Entertainment',
					description:
						"ISFPs' creative expression and appreciation for aesthetics can lead them to careers in the performing arts or entertainment industry. They may thrive as actors, dancers, musicians, or performers, where they can utilize their artistic talents and ability to connect with audiences emotionally.",
				},
				{
					career_path: 'Social Work and Community Outreach',
					description:
						"ISFPs' empathetic and compassionate nature makes them well-suited for careers in social work or community outreach. They have a strong desire to help others and make a positive impact in their communities. They can excel in roles such as social workers, community organizers, or non-profit professionals, where they can support and advocate for vulnerable populations and contribute to social change. Their ability to connect with people on an emotional level and their dedication to improving the well-being of others can drive their success in this field.",
				},
			],
			disclaimer:
				"It's important to consider individual interests, talents, and personal aspirations when choosing a career path. While these suggestions align with the strengths of ISFPs, it's essential to find a career that allows them to express their creativity, connect with others, and work in an environment that resonates with their values and interests.",
		},
	},
	ESTP: {
		pt_alias: 'Persuader',
		description:
			'ESTP stands for Extraverted, Sensing, Thinking, and Perceiving. Individuals with the ESTP personality type are often described as energetic, adventurous, and action-oriented. They are extraverted and thrive in social settings, enjoying the company of others and being at the center of attention. ESTPs have a strong awareness of their surroundings and enjoy engaging their senses in the present moment. They are logical thinkers and rely on their practicality and objectivity when making decisions. ESTPs are spontaneous and adaptable, preferring to go with the flow rather than following strict plans. They have a natural charisma and often excel in activities that require quick thinking, physical coordination, and risk-taking. Overall, ESTPs bring a lively and dynamic energy to their interactions and are often found engaging in exciting and hands-on experiences.',
		strength_info: {
			strengths: [
				{
					strength: 'Adventurous and Bold',
					description:
						'ESTPs are known for their adventurous spirit and willingness to take risks. They thrive in high-energy and dynamic environments, seeking out new and exciting experiences. Their fearlessness and boldness allow them to embrace challenges and adapt quickly to changing situations.',
				},
				{
					strength: 'Excellent in Crisis Situations',
					description:
						'ESTPs excel in high-pressure and crisis situations. They have a natural ability to think on their feet, make quick decisions, and take immediate action. Their ability to remain calm and focused under stress allows them to navigate challenging circumstances with efficiency and effectiveness.',
				},
				{
					strength: 'Charming and Persuasive',
					description:
						'ESTPs possess a charismatic and outgoing nature that makes them adept at building connections with others. They have strong communication skills and are persuasive in their interactions. Their ability to read people and adapt their communication style enables them to influence and motivate others effectively.',
				},
			],
			disclaimer:
				"These strengths contribute to the ESTP's ability to engage in adventurous pursuits, handle crisis situations with ease, and connect with a wide range of people. However, it's important to recognize that individual strengths may vary among ESTPs, and personal interests and experiences can further shape their talents and abilities.",
		},
		weakness_info: {
			weaknesses: [
				{
					weakness: 'Impulsiveness',
					description:
						'ESTPs can be impulsive and prone to making decisions based on immediate gratification rather than considering long-term consequences. Their desire for excitement and novelty may lead them to overlook potential risks or overlook important details, which can have negative outcomes.',
				},
				{
					weakness: 'Difficulty with Long-term Planning',
					description:
						'ESTPs prefer to live in the present moment and can struggle with long-term planning and setting future goals. They may find it challenging to stick to a structured timeline or commit to long-term projects, preferring instead to focus on immediate opportunities and experiences.',
				},
				{
					weakness: "Insensitivity to Others' Emotions",
					description:
						"Due to their focus on logic and practicality, ESTPs may sometimes overlook or downplay the emotional needs of others. They may struggle to empathize with others' feelings, which can lead to misunderstandings or conflicts in relationships. This can create challenges in situations that require a high degree of emotional sensitivity and understanding.",
				},
			],
			disclaimer:
				"It's important to note that these weaknesses are not universal to all ESTPs, and individuals may exhibit different strengths and weaknesses within their personality type. Awareness of these challenges can help ESTPs develop strategies to balance their impulsiveness, engage in long-term planning when necessary, and improve their ability to understand and respond to others' emotions.",
		},
		career_path_info: {
			career_paths: [
				{
					career_path: 'Entrepreneurship and Sales',
					description:
						"ESTPs' adventurous nature, charm, and ability to think on their feet make them well-suited for entrepreneurship and sales roles. They thrive in dynamic environments where they can take risks, make quick decisions, and engage with others. Their persuasive communication skills and ability to adapt to changing circumstances can lead to success in these fields.",
				},
				{
					career_path: 'Emergency Services and First Responders',
					description:
						"ESTPs' ability to handle crisis situations and remain calm under pressure makes them suitable for careers in emergency services. They excel in roles such as firefighters, paramedics, or police officers, where they can utilize their quick thinking, physical agility, and ability to take immediate action.",
				},
				{
					career_path: 'Sports and Athletics',
					description:
						"ESTPs' love for physical activities, their competitive nature, and their ability to perform well under pressure make them well-suited for careers in sports and athletics. They can excel as professional athletes, coaches, or trainers, leveraging their physical skills, strategic thinking, and ability to thrive in high-stakes situations.",
				},
				{
					career_path: 'Entertainment and Performing Arts',
					description:
						"ESTPs' charismatic and outgoing nature, combined with their ability to captivate an audience, can lead them to careers in the entertainment industry. They may find success as actors, entertainers, or public speakers, where they can showcase their natural talent for engaging others and bringing excitement to their performances.",
				},
				{
					career_path: 'Law Enforcement and Investigations',
					description:
						"ESTPs' ability to think quickly, make split-second decisions, and handle high-pressure situations makes them well-suited for careers in law enforcement and investigations. They can excel as police officers, detectives, or special agents, utilizing their physical agility, problem-solving skills, and ability to navigate complex situations.",
				},
			],
			disclaimer:
				"It's important to consider individual interests, talents, and personal aspirations when choosing a career path. While these suggestions align with the strengths of ESTPs, it's essential to find a career that allows them to utilize their strengths, engage in stimulating activities, and experience a sense of adventure and spontaneity.",
		},
	},
	ESFP: {
		pt_alias: 'Performer',
		description:
			'ESFP stands for Extraverted, Sensing, Feeling, and Perceiving. Individuals with the ESFP personality type are often described as outgoing, enthusiastic, and spontaneous. They are extraverted and thrive in social settings, enjoying the company of others and seeking opportunities for excitement and fun. ESFPs have a strong awareness of their surroundings and enjoy engaging their senses in the present moment. They make decisions based on their personal values and emotions, prioritizing harmony and empathy in their interactions. ESFPs are adaptable and flexible, preferring to go with the flow rather than following strict plans. They have a natural ability to connect with others and often bring a sense of energy and joy to their relationships and experiences.',
		strength_info: {
			strengths: [
				{
					strength: 'Enthusiastic and Energetic',
					description:
						'ESFPs bring a contagious enthusiasm and energy to their interactions and activities. They have a zest for life and enjoy engaging in exciting and fun experiences. Their upbeat and lively nature can uplift those around them and create a positive atmosphere.',
				},
				{
					strength: 'Excellent Interpersonal Skills',
					description:
						"ESFPs have a natural ability to connect with others and establish strong relationships. They are warm, approachable, and genuinely interested in people. Their empathy and ability to understand others' emotions make them effective communicators and listeners, fostering deep connections with those around them.",
				},
				{
					strength: 'Adaptability and Flexibility',
					description:
						'ESFPs are highly adaptable and flexible, thriving in dynamic and ever-changing environments. They can easily adjust to new situations and navigate unexpected challenges with ease. Their ability to go with the flow allows them to make the most of opportunities and find joy in the present moment.',
				},
			],
			disclaimer:
				"These strengths contribute to the ESFP's ability to bring enthusiasm to their endeavors, build strong relationships, and navigate diverse social settings. However, it's important to recognize that individual strengths may vary among ESFPs, and personal interests and experiences can further shape their talents and abilities.",
		},
		weakness_info: {
			weaknesses: [
				{
					weakness: 'Impulsiveness and Lack of Planning',
					description:
						'ESFPs can be spontaneous and may struggle with long-term planning and follow-through. Their preference for living in the present moment and seeking immediate gratification can lead to impulsive decisions and a tendency to overlook important details or consequences.',
				},
				{
					weakness: 'Sensitivity to Criticism',
					description:
						"ESFPs are often highly attuned to others' opinions and sensitive to criticism. They may take feedback personally and struggle with handling negative feedback or conflict. This sensitivity can sometimes hinder their growth and development, as they may avoid situations that involve potential criticism or confrontation.",
				},
				{
					weakness: 'Difficulty with Routine and Structure',
					description:
						'ESFPs prefer a flexible and unstructured approach to life, which can make it challenging for them to adhere to strict routines or repetitive tasks. They may feel restless or bored when faced with repetitive or mundane activities, seeking more excitement and variety in their daily lives.',
				},
			],
			disclaimer:
				"It's important to note that these weaknesses are not universal to all ESFPs, and individuals may exhibit different strengths and weaknesses within their personality type. Awareness of these challenges can help ESFPs develop strategies to balance their impulsiveness, handle criticism constructively, and find ways to infuse creativity and variety into their daily routines.",
		},
		career_path_info: {
			career_paths: [
				{
					career_path: 'Performing Arts',
					description:
						"ESFPs' natural enthusiasm, charisma, and love for engaging with others make them well-suited for careers in performing arts, such as acting, dancing, or music. They thrive in creative and expressive environments that allow them to showcase their talents and connect with an audience.",
				},
				{
					career_path: 'Event Planning and Hospitality',
					description:
						"ESFPs' ability to create a lively and engaging atmosphere, coupled with their excellent interpersonal skills, make them suitable for careers in event planning and hospitality. They enjoy organizing and hosting events, bringing people together and ensuring a memorable experience.",
				},
				{
					career_path: 'Sales and Customer Service',
					description:
						"ESFPs' outgoing and personable nature, combined with their ability to empathize with others, can lead them to excel in sales and customer service roles. They have a knack for connecting with people, understanding their needs, and providing personalized solutions.",
				},
				{
					career_path: 'Recreation and Fitness',
					description:
						"ESFPs' love for adventure, physical activity, and social interaction can make them well-suited for careers in recreation and fitness. They may find fulfillment as fitness instructors, outdoor guides, or recreational coordinators, engaging others in enjoyable and active pursuits.",
				},
				{
					career_path: 'Tourism',
					description:
						"ESFPs' outgoing and sociable nature, coupled with their ability to create a vibrant and enjoyable atmosphere, make them well-suited for careers in tourism and hospitality management. They can thrive in roles that involve organizing and coordinating travel experiences, managing events, or overseeing customer service in the hospitality industry.",
				},
			],
			disclaimer:
				"It's important for ESFPs to consider their own interests, values, and personal goals when choosing a career path. While these suggestions align with the strengths of ESFPs, finding a career that allows them to express their creativity, connect with others, and experience excitement and variety is essential for their fulfillment and success.",
		},
	},
};

///////////////////

// export const PersonalityType = {
// 	ISTJ: {
// 		character: 'Inspector',
// 		description:
// 			'At first glance, ISTJs are intimidating. They appear serious, formal, and proper. They also love traditions and old-school values that uphold patience, hard work, honor, and social and cultural responsibility. They are reserved, calm, quiet, and upright. These traits result from the combination of I, S, T, and J, a personality type that is often misunderstood.',
// 	},
// 	INFJ: {
// 		character: 'Counselor',
// 		description:
// 			'INFJs are visionaries and idealists who ooze creative imagination and brilliant ideas. They have a different, and usually more profound, way of looking at the world. They have a substance and depth in the way they think, never taking anything at surface level or accepting things the way they are. Others may sometimes perceive them as weird or amusing because of their different outlook on life.',
// 	},
// 	INTJ: {
// 		character: 'Mastermind',
// 		description:
// 			'INTJs, as introverts, are quiet, reserved, and comfortable being alone. They are usually self-sufficient and would rather work alone than in a group. Socializing drains an introvert’s energy, causing them to need to recharge. INTJs are interested in ideas and theories. When observing the world they are always questioning why things happen the way they do. They excel at developing plans and strategies, and don’t like uncertainty.',
// 	},
// 	ENFJ: {
// 		character: 'Giver',
// 		description:
// 			'ENFJs are people-focused individuals. They are extroverted, idealistic, charismatic, outspoken, highly principled and ethical, and usually know how to connect with others no matter their background or personality. Mainly relying on intuition and feelings, they tend to live in their imagination rather than in the real world. Instead of focusing on living in the “now” and what is currently happening, ENFJs tend to concentrate on the abstract and what could possibly happen in the future.',
// 	},
// 	ISTP: {
// 		character: 'Craftsman',
// 		description:
// 			'ISTPs are mysterious people who are usually very rational and logical, but also quite spontaneous and enthusiastic. Their personality traits are less easily recognizable than those of other types, and even people who know them well can’t always anticipate their reactions. Deep down, ISTPs are spontaneous, unpredictable individuals, but they hide those traits from the outside world, often very successfully.',
// 	},
// 	ESFJ: {
// 		character: 'Provider',
// 		description:
// 			'ESFJs are the stereotypical extroverts. They are social butterflies, and their need to interact with others and make people happy usually ends up making them popular. The ESFJ usually tends to be the cheerleader or sports hero in high school and college. Later on in life, they continue to revel in the spotlight, and are primarily focused on organizing social events for their families, friends and communities. ESFJ is a common personality type and one that is liked by many people.',
// 	},
// 	INFP: {
// 		character: 'Idealist',
// 		description:
// 			'INFPs, like most introverts, are quiet and reserved. They prefer not to talk about themselves, especially in the first encounter with a new person. They like spending time alone in quiet places where they can make sense of what is happening around them. They love analyzing signs and symbols, and consider them to be metaphors that have deeper meanings related to life. They are lost in their imagination and daydreams, always drowned in the depth of their thoughts, fantasies, and ideas.',
// 	},
// 	ESFP: {
// 		character: 'Performer',
// 		description:
// 			'ESFPs have an Extraverted, Observant, Feeling and Perceiving personality, and are commonly seen as Entertainers. Born to be in front of others and to capture the stage, ESFPs love the spotlight. ESFPs are thoughtful explorers who love learning and sharing what they learn with others. ESFPs are “people people” with strong interpersonal skills. They are lively and fun, and enjoy being the center of attention. They are warm, generous, and friendly, sympathetic and concerned for other people’s well-being.',
// 	},
// 	ENFP: {
// 		character: 'Champion',
// 		description:
// 			'ENFPs have an Extraverted, Intuitive, Feeling and Perceiving personality. This personality type is highly individualistic and Champions strive toward creating their own methods, looks, actions, habits, and ideas — they do not like cookie cutter people and hate when they are forced to live inside a box. They like to be around other people and have a strong intuitive nature when it comes to themselves and others. They operate from their feelings most of the time, and they are highly perceptive and thoughtful.',
// 	},
// 	ESTP: {
// 		character: 'Doer',
// 		description:
// 			'ESTPs have an Extraverted, Sensing, Thinking, and Perceptive personality. ESTPs are governed by the need for social interaction, feelings and emotions, logical processes and reasoning, along with a need for freedom. Theory and abstracts don’t keep ESTP’s interested for long. ESTPs leap before they look, fixing their mistakes as they go, rather than sitting idle or preparing contingency plans.',
// 	},
// 	ESTJ: {
// 		character: 'Supervisor',
// 		description:
// 			'ESTJs are organized, honest, dedicated, dignified, traditional, and are great believers of doing what they believe is right and socially acceptable. Though the paths towards “good” and “right” are difficult, they are glad to take their place as the leaders of the pack. They are the epitome of good citizenry. People look to ESTJs for guidance and counsel, and ESTJs are always happy that they are approached for help.',
// 	},
// 	ENTJ: {
// 		character: 'Commander',
// 		description:
// 			'An ENTJ’s primary mode of living focuses on external aspects and all things are dealt with rationally and logically. Their secondary mode of operation is internal, where intuition and reasoning take effect. ENTJs are natural born leaders among the 16 personality types and like being in charge. They live in a world of possibilities and they often see challenges and obstacles as great opportunities to push themselves. They seem to have a natural gift for leadership, making decisions, and considering options and ideas quickly yet carefully. They are “take charge” people who do not like to sit still.',
// 	},
// 	INTP: {
// 		character: 'Thinker',
// 		description:
// 			'INTPs are well known for their brilliant theories and unrelenting logic, which makes sense since they are arguably the most logical minded of all the personality types. They love patterns, have a keen eye for picking up on discrepancies, and a good ability to read people, making it a bad idea to lie to an INTP. People of this personality type aren’t interested in practical, day-to-day activities and maintenance, but when they find an environment where their creative genius and potential can be expressed, there is no limit to the time and energy INTPs will expend in developing an insightful and unbiased solution.',
// 	},
// 	ISFJ: {
// 		character: 'Nurturer',
// 		description:
// 			'ISFJs are philanthropists and they are always ready to give back and return generosity with even more generosity. The people and things they believe in will be upheld and supported with enthusiasm and unselfishness. ISFJs are warm and kind-hearted. They value harmony and cooperation, and are likely to be very sensitive to other people’s feelings. People value the ISFJ for their consideration and awareness, and their ability to bring out the best in others.',
// 	},
// 	ENTP: {
// 		character: 'Visionary',
// 		description:
// 			'Those with the ENTP personality are some of the rarest in the world, which is completely understandable. Although they are extroverts, they don’t enjoy small talk and may not thrive in many social situations, especially those that involve people who are too different from the ENTP. ENTPs are intelligent and knowledgeable need to be constantly mentally stimulated. They have the ability to discuss theories and facts in extensive detail. They are logical, rational, and objective in their approach to information and arguments.',
// 	},
// 	ISFP: {
// 		character: 'Composer',
// 		description:
// 			'ISFPs are introverts that do not seem like introverts. It is because even if they have difficulties connecting to other people at first, they become warm, approachable, and friendly eventually. They are fun to be with and very spontaneous, which makes them the perfect friend to tag along in whatever activity, regardless if planned or unplanned. ISFPs want to live their life to the fullest and embrace the present, so they make sure they are always out to explore new things and discover new experiences. It is in experience that they find wisdom, so they do see more value in meeting new people than other introverts.',
// 	},
// };
